<template>
  <div>
    <div class="PassengerSelection">
      <div>
        <div class="w-100 index">
          <div class="w-100" v-if="isBookNow">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-4 col-md-12 pr-0">
                <div class="book-now-area">
                  <h1>{{ $t('are_you_looking_for_airport_transfer') }} !!!</h1>
                  <span>{{ $t('you_have_come_to_the_right_place') }} </span>

                  <TheButton
                    customClass="w-100 mt-3"
                    v-on:click="isBookNow = !isBookNow && this.setStep(1)"
                    size="btn-sm"
                    >{{ $t('book_now') }}
                  </TheButton>

                  <!-- <button v-on:click="isBookNow = !isBookNow" class="btn btn-book-now">
                    Book Now
                  </button> -->
                </div>
              </div>
              <div class="col-lg-8 col-md-12">
                <carousel
                  v-if="homepageSliders && homepageSliders.values"
                  :perPage="1"
                  :mouseDrag="true"
                  :autoplay="true"
                  :autoplaySpeed="4000"
                >
                  <slide
                    v-for="item in homepageSliders.values"
                    :name="item.name"
                    :key="item.id"
                  >
                    <img class="w-100" :src="item.value" :alt="item.name" />
                  </slide>
                </carousel>
              </div>
            </div>
          </div>

          <div class="wrapper-stepper" v-if="!isBookNow">
            <div v-dragscroll:firstchilddrag class="stepper">
              <div class="stepper-progress">
                <div class="stepper-progress-bar"></div>
              </div>

              <div
                class="stepper-item"
                :class="{ current: step == item, success: step > item }"
                v-for="item in 4"
                :key="item"
              >
                <div class="stepper-item-counter">
                  <span class="number">
                    <a v-if="item == 1">
                      <b>{{ item }}.</b>{{ $t('travel_details') }}
                    </a>
                    <a v-if="item == 2">
                      <b>{{ item }}.</b>{{ $t('choose_your_vehicle') }}
                    </a>
                    <a v-if="item == 3">
                      <b>{{ item }}.</b>{{ $t('user_information') }}
                    </a>
                    <a v-if="item == 4">
                      <b>{{ item }}.</b>{{ $t('payment') }}
                    </a>
                  </span>
                </div>
                <!-- <span class="stepper-item-title"> Paso {{ item }} </span> -->
              </div>
            </div>

            <div class="stepper-content" v-for="item in 4" :key="item">
              <div class="stepper-pane" v-if="step == item">
                <div v-if="step == 1">
                  <TheTravelDetails />
                </div>
                <div v-if="step == 2">
                  <TheChooseYourVehicle />
                </div>
                <div v-if="step == 3">
                  <TheUserInformation />
                </div>
                <div v-if="step == 4"><ThePayment /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheTravelDetails from '@/components/TheTravelDetails'
import TheChooseYourVehicle from '@/components/TheChooseYourVehicle'
import TheUserInformation from '@/components/TheUserInformation'
import ThePayment from '@/components/ThePayment'
import { mapGetters, mapActions } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Booking',

  data() {
    return {
      isBookNow: true,
      loading: false,
      homepageSliders: null,

      settings: {
        arrows: false,
        dots: false,
        autoplay: false,
        slidesPerRow: 1,
        swipe: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 991
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesPerRow: 1,
              infinite: true,
              centerMode: true
            }
          }
        ]
      }
    }
  },

  components: {
    TheButton,
    TheTravelDetails,
    TheChooseYourVehicle,
    TheUserInformation,
    ThePayment,

    Carousel,
    Slide
  },
  mounted() {
    console.log(this.getSettings)
  },
  methods: {
    setStep(setIndex) {
      this.setIndexAction(setIndex)

      if (setIndex == 2 && this.cars) {
        this.setIndexAction(setIndex)
        return
      }

      if (setIndex == 3 && this.selectedCar) {
        this.setIndexAction(setIndex)
        return
      }

      if (setIndex == 4 && this.booking) {
        this.setIndexAction(setIndex)
        return
      }

      if (setIndex == 1) {
        this.setIndexAction(setIndex)
        return
      }
    },
    ...mapActions({
      setIndexAction: 'bookingStep/setIndex'
    })
  },
  directives: {
    dragscroll
  },
  created() {
    this.$store.dispatch('homeSettings/setSettings').then(() => {
      this.homepageSliders =
        this.$store.getters['homeSettings/getSettingByCode'](
          'home_page_sliders'
        )
    })
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      selectedCar: 'booking/getSelectedCar',
      booking: 'booking/getBooking',
      cars: 'booking/getCarsList',
      getSettings: 'homeSettings/getSettings'
    })
  }
}
</script>

<style lang="scss">
$default: rgb(var(--text-color));
$green-1: rgb(var(--secondary-color));
$transiton: all 500ms ease;

.book-now-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;

  h1 {
    font-size: 32px;
    color: rgb(var(--text-color));
    font-weight: 500;
  }

  span {
    font-size: 16px;
    color: rgb(var(--text-color));
    margin-top: 15px;
  }
}
.book-now-img-area {
  img {
    width: 100%;
  }
}
.btn-book-now {
  margin-top: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txnext-step {
  color: rgb(var(--secondary-color));
  font-weight: 600;
}

.wrapper-stepper {
  width: 100%;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  margin-bottom: 24px;

  &-progress {
    position: absolute;
    background-color: rgb(var(--border-color));
    height: 2px;
    z-index: -1;
    left: 0;
    right: 0;
    margin-bottom: -26px;
  }
}

.stepper-item {
  display: flex;
  flex-direction: revert;
  align-items: center;
  color: rgb(var(--text-color));
  transition: all 500ms ease;
  justify-content: space-between;
  transition: $transiton;

  &-counter {
    position: relative;
    .icon-success {
      position: absolute;
      opacity: 0;
      transform: scale(0);
      width: 24px;
      transition: $transiton;
    }

    .number {
      font-size: 22px;
      transition: $transiton;

      a {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  &-title {
    position: absolute;
    font-size: 14px;
    bottom: -24px;
  }
}
.stepper-item-counter {
  border-bottom: 5px solid transparent;
  color: rgb(var(--border-color));
}
.stepper-item.success {
  .stepper-item-counter {
    color: rgb(var(--text-color));
    .icon-success {
      opacity: 0;
      transform: scale(1);
    }

    .number {
      opacity: 1;
      transform: scale(0);
    }
  }

  .stepper-item-title {
    color: rgb(var(--secondary-color));
  }
}

.stepper-item.current {
  .number {
    border-bottom: 2px solid rgb(var(--secondary-color));
  }
  .stepper-item-counter {
    border-style: solid;
    border-color: rgb(var(--secondary-color));
    color: #000;
    b {
      color: rgb(var(--secondary-color));
    }
  }

  .stepper-item-title {
    color: #818181;
  }
}

.stepper-pane {
  color: #333;
  text-align: center;
  // padding: 120px 60px;
  // box-shadow: 0 8px 12px rgba($color: #000000, $alpha: 0.09);
  margin: 50px 0;
}

.controls {
  display: flex;
}

.btn {
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-next-step {
    background-color: $green-1;
    border-color: $green-1;
    color: #fff;
  }
}
</style>

<style lang="scss">
.index {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  .pac-target-input {
    border: 1px solid rgb(var(--border-color));
    color: rgb(var(--text-color));
    border-radius: 4px;
    font-size: 1rem;
    padding: 15px 10px;
  }
}

label {
  margin-bottom: 10px;
  display: block;
}

.form-group {
  input {
    width: 100%;
  }
}
</style>

<style lang="css">
.VueCarousel-dot {
  background-color: rgba(0, 0, 0, 0.369) !important;
  border: 1px solid 00000069;
  padding: 1px !important;
  margin-right: 5px;
}
.VueCarousel-dot--active {
  background-color: rgb(var(--secondary-color)) !important;
  border: 1px solid #00000069 !important;
  padding: 1px;
}
</style>
