<template>
  <div class="reservation-item- pb-4 pt-3">
    <div class="the-choose-your-vehicle-card">
      <div class="the-choose-your-vehicle-img-box">
        <img class="w-100" :src="this.getSelectedcar.image" />
      </div>
      <section
        :class="{ hovering: isHovering }"
        class="align-items-center d-flex justify-content-between price-area"
      >
        <strong>{{ this.getSelectedcar.name }}</strong>
        <abbr class="d-flex flex-column align-items-end">
          <small v-if="isDiscounted">CHF {{ this.getSelectedcar.price }}</small>
          <span>CHF {{ this.getSelectedcar.discount_price }}</span>
        </abbr>
      </section>

      <section class="d-flex flex-column align-items-start feature">
        <abbr
          ><i class="ri-user-add-line"></i>
          <span
            >{{ $t('max') }}. {{ this.getSelectedcar.person }}
            {{ $t('person') }}</span
          ></abbr
        >
        <abbr
          ><i class="ri-suitcase-3-line"></i>
          <span
            >{{ $t('max') }}. {{ this.getSelectedcar.baggage }}
            {{ $t('suitcase') }}</span
          ></abbr
        >

        <abbr v-if="getSelectedcar.free_cancellation > 0"
          ><i class="ri-time-line"></i>
          <span
            >{{ $t('free_cancellation_up_to') }}
            {{ getSelectedcar.free_cancellation }} {{ $t('hours') }}</span
          ></abbr
        >
      </section>
      <section
        class="from-to-date-time d-flex justify-content-start flex-column align-items-start"
      >
        <div
          class="section-item d-flex justify-content-start flex-column align-items-start"
        >
          <b>{{ $t('from') }}</b>
          <span class="text-left">{{ this.origin.name }}</span>
        </div>
        <i class="ri-arrow-down-line mt-3"></i>
        <div
          class="section-item d-flex justify-content-start flex-column align-items-start"
        >
          <b>{{ $t('to') }}</b>
          <span class="text-left">{{ this.destination.name }}</span>
        </div>
        <i class="ri-arrow-down-line mt-3"></i>
        <div class="section-item d-flex justify-content-start w-100">
          <div class="d-flex flex-column align-items-start mr-5">
            <b>{{ $t('date') }}</b>
            <span class="text-left">{{ format_date(this.getFromDate) }}</span>
          </div>
          <div class="d-flex flex-column align-items-start">
            <b class="text-left">{{ $t('time') }}</b>
            <span class="text-left">{{ format_time(this.getFromTime) }}</span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      isHovering: false,
      destination: [],
      origin: [],
      distance: null,
      duration: null
    }
  },
  computed: {
    ...mapGetters({
      getFromDate: 'booking/getFromDate',
      getFromTime: 'booking/getFromTime',
      getSelectedcar: 'booking/getSelectedCar'
    }),
    getDistance: function () {
      return `${Math.ceil(this.distance / 1000)} km`
    },
    getDuration: function () {
      return `${Math.ceil(this.duration / 60)} min`
    },
    isDiscounted: function () {
      return this.getSelectedcar.price !== this.getSelectedcar.discount_price
    }
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    }
  },
  mounted() {
    const booking = this.$store.state.booking
    this.polyline_points = booking.polyline_points
    this.origin = booking.origin
    this.destination = booking.destination
    this.distance = booking.distance
    this.duration = booking.duration
  }
}
</script>
<style scoped>
.slide {
  box-shadow: 0 6px 15px -3px rgb(0 0 0/0.3);
  padding: 25px;
  border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.hovering {
  border-bottom: 3px solid rgb(var(--secondary-color)) !important;
}
.reservation-item- {
  justify-content: flex-start;
}
.the-choose-your-vehicle-card {
  margin-right: 20px;
  margin-bottom: 20px;

  .the-choose-your-vehicle-img-box {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .price-area {
    border-bottom: 3px solid rgb(var(--secondary-color));
    margin-bottom: 17px;
    padding-bottom: 7px;
    strong {
      font-size: 16px;
    }
    small {
      text-decoration: line-through;
      color: rgb(var(--tertiary-color));
      font-size: 12px;
    }

    span {
      font-size: 15px;
      color: rgb(var(--secondary-color));
      font-weight: 600;
    }
  }
  .button-container {
    width: 100%;
  }

  .feature {
    margin-bottom: 17px;
    border-bottom: 3px solid rgb(var(--border-color));
    padding-bottom: 10px;
    abbr {
      display: flex;
      align-items: start;

      i {
        margin-right: 10px;
        font-size: 17px;
        color: rgb(var(--tertiary-color));
      }
      span {
        font: normal normal normal 13px/25px Poppins;
        color: rgb(var(--text-color));
        font-weight: 500;
        text-align: left;
      }
    }
  }
  .section-item {
    span {
      color: rgba(73, 80, 87, 1);
    }
  }
}
</style>
