<template>
  <label class="label">
    <slot />
  </label>
</template>

<script lang="js">
export default {
  name: 'TheLabel'
}
</script>

<style scoped>
.label {
  color: rgb(var(--primary-color));
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
