<template>
  <div class="row">
    <div class="col-lg-6 col-sm-12 my-account-wrapper">
      <h1>{{ $t('my_account') }}</h1>
      <span>{{ $t('you_can_edit_your_reservation_information') }}</span>
    </div>
    <div class="col-lg-6 col-sm-12">
      <form class="form" @submit.prevent="submit">
        <div
          class="form-item form-group"
          :class="{ 'form-group--error': $v.form.name.$error }"
        >
          <TheLabel
            >{{ $t('name') }}
            <span class="error text-danger" v-if="!$v.form.name.required">
              *
            </span>
          </TheLabel>
          <input
            class="form-control"
            :placeholder="$t('name')"
            v-model.trim="$v.form.name.$model"
          />
        </div>
        <div
          class="form-item form-group"
          :class="{ 'form-group--error': $v.form.surname.$error }"
        >
          <TheLabel
            >{{ $t('surname') }}
            <span class="error text-danger" v-if="!$v.form.surname.required">
              *
            </span>
          </TheLabel>
          <input
            class="form-control"
            :placeholder="$t('surname')"
            v-model.trim="$v.form.surname.$model"
          />
        </div>
        <div
          class="form-item form-group"
          :class="{ 'form-group--error': $v.form.email.$error }"
        >
          <TheLabel
            >{{ $t('email') }}
            <span class="error text-danger" v-if="!$v.form.email.required">
              *
            </span>
          </TheLabel>
          <input
            class="form-control"
            :placeholder="$t('email')"
            v-model.trim="$v.form.email.$model"
          />
        </div>
        <div class="form-item form-group">
          <TheLabel>{{ $t('phone') }} </TheLabel>
          <vue-tel-input
            v-bind="this.bindProps"
            v-model="form.phone_number"
            class="tel-input"
            :placeholder="$t('phone')"
            v-on:country-changed="countryChanged"
            :key="componentKey"
            @validate="validatePhone"
          ></vue-tel-input>
          <span class="error text-danger" v-if="phoneIsValid === false">
            {{ $t('phone_is_not_valid') }}
          </span>
        </div>
        <div class="form-item form-group" style="flex-direction: row">
          <TheButton :loading="loading">{{ $t('save') }}</TheButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheLabel from '@/components/TheLabel'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import countries from '../assets/telInputcountryData.js'

export default {
  name: 'Profile',
  mounted() {
    const user = this.$store.state.auth.user
    if (user) {
      this.form.email = user.email
      this.form.name = user.name
      this.form.surname = user.surname
      this.form.phone_number = user.country_code + user.phone
      this.user_type = user.type
      this.form.country_code = user.country_code
      this.componentKey++
    }
  },
  data() {
    return {
      phoneIsValid: undefined,
      countries: countries,
      bindProps: {
        autofocus: false,
        autoFormat: true,
        inputOptions: {
          showDialCode: true
        }
      },
      componentKey: 0, // initial key
      form: {
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        country_code: ''
      },
      loading: false,
      user_type: null
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required
      },
      email: {
        required
      }
    }
  },
  computed: {},
  methods: {
    validatePhone(object) {
      this.phoneIsValid = object.valid
    },
    countryChanged(country) {
      this.form.country_code = country.dialCode
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    ...mapActions({
      updateUserAction: 'auth/updateUser'
    }),
    submit: function () {
      this.$v.$touch()

      if (this.phoneIsValid !== true) {
        this.phoneIsValid = false
      }

      if (this.$v.$invalid || this.phoneIsValid !== true) {
        this.submitStatus = 'ERROR'
      } else {
        this.loading = true

        const data = {
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.email,
          phone: this.formatPhoneNumber(
            this.form.phone_number,
            `+${this.form.country_code}`
          ),
          country_code: `+${this.form.country_code}`
        }
        this.updateUserAction(data)
          .then(() => {
            Swal.fire({
              title: this.$t('success'),
              text: this.$t('registration_successful'),
              icon: 'success',
              confirmButtonText: this.$t('close')
            })
            this.loading = false
          })
          .catch((error) => {
            let errorMessage = error.response.data.message.email
            Swal.fire({
              title: this.$t('errors.an_error_occured'),
              html: errorMessage,
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
            this.loading = false
          })
      }
    }
  },
  components: {
    TheButton,
    TheLabel,
    VueTelInput
  }
}
</script>

<style lang="scss" scoped>
.my-account-wrapper {
  h1 {
    margin-top: 90px;
    color: var(--text-color);
    text-align: left;
    font: normal normal medium 29px/43px Poppins;
    letter-spacing: 0px;
    opacity: 1;
  }
  span {
    margin-top: 30px;
    color: var(--text-color);
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
  }
}
.textbox {
  width: 100%;
}
.form-header {
  h1 {
    color: rgb(var(--primary-color));
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
}
.form {
  // @media (--md) {
  //   display: grid;
  //   grid-gap: 30px;
  //   grid-template-columns: 1fr 1fr;
  // }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 15px !important;

    @media (--md) {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .span-2 {
    grid-column: span 2;
  }
}
</style>
