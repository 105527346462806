<template>
  <div v-if="showModal" v-on:close="showModal = false">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <h5 name="header">
            {{ $t('continue_payment') }}
          </h5>
          <div>
            <iframe
              v-if="iframe.loaded"
              :src="iframe.src"
              :style="iframe.style"
              @iframe-load="onLoad"
              width="500px"
              height="500px"
              scrolling="no"
              frameborder="0"
            />
          </div>
          <div class="d-flex justify-content-end align-items-end">
            <TheButton v-on:click="$emit('close', false)" size="btn-sm">{{
              $t('close')
            }}</TheButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'

export default {
  name: 'ThePaymentModal',
  data() {
    return {
      iframe: {
        loaded: false,
        src: '',
        style: null
      }
    }
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    bookingId: {
      type: String
    },
    paymentType: {
      type: String
    }
  },
  components: {
    TheButton
  },
  mounted() {
    this.setIframeSrc()
    this.iframe.loaded = true
  },
  computed: {},
  methods: {
    setIframeSrc() {
      var baseUrl = process.env.VUE_APP_API_BASE_URL
      this.iframe.src = `${baseUrl}api/payment?bookingId=${this.bookingId}&paymentType=${this.paymentType}`
    },
    onLoad(frame) {
      this.myIframe = frame.contentWindow
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.4s ease;
}

button {
  border: none;
  background: #f44336;
  color: #fff;
  padding: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 550px;
  margin: 0px auto;
  padding: 15px 15px;
  min-height: 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
  font-family: Helvetica, Arial, sans-serif;
  p {
    max-height: 600px;
    overflow: auto;
  }
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    p {
      max-height: 300px;
      overflow: auto;
    }
  }
}
</style>
