<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="w-100">
    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="'Title'"
      v-bind:description="'description'"
      @close="logChange"
    />

    <div class="row">
      <div class="col-lg-6 col-md-12 login-form-left-text-area">
        <h4>{{ $t('please_login') }}</h4>
        <span>
          {{ $t('please_login_or_free_to_continue_booking_sign_up') }}</span
        >
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="login-form-container" v-if="verification_code">
          <form @submit.prevent="submit" class="login-form">
            <div class="form-item">
              <TheLabel>{{ $t('mobile_phone') }}</TheLabel>
              <vue-tel-input
                autoformat="false"
                v-bind="this.bindProps"
                @keypress.native="isInputNumber"
                v-model="form.phone"
                class="tel-input"
                :placeholder="$t('please_enter_mobile_phone_number')"
                v-on:country-changed="countryChanged"
                @validate="validatePhone"
              >
              </vue-tel-input>
              <span class="error text-danger" v-if="phoneIsValid === false">
                {{ $t('phone_is_not_valid') }}
              </span>
            </div>

            <div
              class="destination d-flex justify-content-between align-items-center form-btn-area"
            >
              <TheButton size="btn-sm" :loading="loading">{{
                $t('verify')
              }}</TheButton>
            </div>
          </form>
        </div>

        <div class="verification_code" v-if="!verification_code">
          <TheLoginVerifyCode
            v-bind:userIdd="userId"
            v-bind:phone="this.form.phone"
            :code="this.code"
          ></TheLoginVerifyCode>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheLabel from '@/components/TheLabel'
import { mapActions } from 'vuex'
// import Swal from 'sweetalert2'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import TheLoginVerifyCode from '@/components/TheLoginVerifyCode'
import TheModal from '@/components/TheModal'

export default {
  name: 'Login',
  components: {
    TheButton,
    TheLabel,
    VueTelInput,
    TheLoginVerifyCode,
    TheModal
  },
  data() {
    return {
      phoneIsValid: undefined,

      showModal: false,
      form: {
        phone: '',
        dialCode: null,
        iso2: null
      },
      userId: '',
      otp: '',
      code: '',
      privacy: false,
      loading: false,
      verification_code: true,
      bindProps: {
        defaultCountry: 'CH',
        autofocus: false,
        autoFormat: true,
        inputOptions: {
          showDialCode: true
        }
      }
    }
  },

  methods: {
    validatePhone(object) {
      this.phoneIsValid = object.valid
    },
    countryChanged(country) {
      this.form.dialCode = country.dialCode
      this.form.iso2 = country.iso2
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    ...mapActions({
      generate: 'auth/generate'
    }),
    logChange(event) {
      this.showModal = event
    },
    submit() {
      if (this.phoneIsValid !== true) {
        this.phoneIsValid = false
      }

      if (this.phoneIsValid === true) {
        this.loading = true
        var data = {
          phone: this.formatPhoneNumber(
            this.form.phone,
            `+${this.form.dialCode}`
          ),
          country_code: `+${this.form.dialCode}`,
          dl_code: `${this.form.iso2}`
        }

        this.generate(data)
          .then((data) => {
            if (data !== null && data.user_id !== null) {
              this.userId = data.user_id
              this.verification_code = false
              this.code = data.otp
            }

            this.loading = false
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status == 400) {
                this.$router.replace({
                  name: 'MyAccount'
                })
              }
            }

            ;(this.loading = false), (this.verification_code = false)
          })
      } else {
        //
      }
    },
    isInputNumber(evt) {
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode !== 43 && // '+'
        charCode !== 32 && // 'space'
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tel-input {
  input {
    height: 42px !important;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
}
.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      label {
        margin-bottom: 15px;
      }
    }
  }

  .form-btn-area {
    a {
      font-size: 16px;
      color: rgb(var(--text-color));
      font-weight: 500;
    }
  }
}

.login-form-left-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h4 {
    color: var(--primary-color);
    text-align: left;
    font: normal normal 29px/43px Poppins;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 29px;
    margin-bottom: 30px;
  }

  span {
    color: var(--text-color);
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.verification_code {
  a {
    &:last-child {
      text-align: right;
      font: normal normal normal 10px/16px Poppins;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      display: flex;
      align-items: center;
    }

    &:first-child {
      color: var(--unnamed-color-22a72f);
      text-align: right;
      font: normal normal medium 12px/18px Poppins;
      letter-spacing: 0px;
      color: #22a72f;
      opacity: 1;
    }
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  span {
    color: var(--unnamed-color-202020);
    text-align: right;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }
}
</style>
