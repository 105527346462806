<template>
  <div class="row">
    <div class="col-lg-4 col-md-12 bg-white"><TheReservationSummary /></div>
    <div class="col-lg-8 col-md-12">
      <TheUserInformationForm />
    </div>
  </div>
</template>
<script>
import TheUserInformationForm from '@/components/TheUserInformationForm'
import TheReservationSummary from '@/components/TheReservationSummary'

export default {
  data() {
    return {}
  },
  components: {
    TheUserInformationForm,
    TheReservationSummary
  }
}
</script>
<style scoped>
.slide {
  box-shadow: 0 6px 15px -3px rgb(0 0 0/0.3);
  padding: 25px;
  border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.hovering {
  border-bottom: 3px solid rgb(var(--secondary-color)) !important;
}
.carousel-item- {
  justify-content: flex-start;
}
.the-choose-your-vehicle-card {
  margin-right: 20px;
  margin-bottom: 20px;

  .the-choose-your-vehicle-img-box {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .price-area {
    border-bottom: 3px solid rgb(var(--border-color));
    margin-bottom: 17px;
    padding-bottom: 7px;
    strong {
      font-size: 16px;
    }
    small {
      text-decoration: line-through;
      color: rgb(var(--tertiary-color));
      font-size: 9px;
    }

    span {
      font-size: 15px;
      color: rgb(var(--secondary-color));
      font-weight: 600;
    }
  }
  .button-container {
    width: 100%;
  }

  .feature {
    margin-bottom: 17px;

    abbr {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 17px;
        color: rgb(var(--tertiary-color));
      }
      span {
        font: normal normal normal 13px/25px Poppins;
        color: rgb(var(--text-color));
        font-weight: 500;
      }
    }
  }
}
</style>
