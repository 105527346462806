<template>
  <div class="contractdetail" v-html="contract"></div>
</template>
<script>
import axios from 'axios'
export default {
  mounted() {
    axios
      .get(`api/contracts/active/${this.$route.params.id}`)
      .then((response) => {
        this.contract = response.data
      })
  },
  data() {
    return {
      contract: null
    }
  }
}
</script>

<style scoped>
div.contractdetail {
  white-space: pre-line;
}
</style>
