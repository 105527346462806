<template>
  <form @submit.prevent="signInOtp" class="register w-100">
    <div class="form-item">
      <div class="d-flex flex-row justify-content-between mb-3">
        <strong class="verify-code-title">{{ $t('verification_code') }}</strong>
        <a
          v-if="!isUserInformationForm"
          href="/login"
          class="change-phone-number"
          ><i class="ri-arrow-left-line"></i> {{ $t('change_phone_number') }}</a
        >
        <a
          v-if="isUserInformationForm"
          v-on:click="prewVerify()"
          class="change-phone-number"
          ><i class="ri-arrow-left-line"></i> {{ $t('change_phone_number') }}</a
        >
      </div>
      <input
        class="form-control"
        :placeholder="$t('code')"
        v-model.trim="otp"
      />
    </div>
    <div
      class="destination d-flex justify-content-between align-items-center form-btn-area"
    >
      <div class="d-flex justify-content-between w-100 mt-3">
        <TheButton
          :disabled="this.timeLeft === '0:00'"
          size="btn-sm"
          :loading="loading"
          >{{ $t('verify') }}</TheButton
        >
        <div class="d-flex flex-column justify-content-start align-items-end">
          <TheTimeCountDown
            minutes="1"
            seconds="30"
            ref="logoutTimer"
            @onTimerReset="didntGetTheCode"
            v-on:logout="notAuthenticated = true"
            inline-template
          >
            <span
              class="time-count-down"
              :class="timeLeft === '0:00' ? 'didnt-get-the-code' : ''"
              v-on:click="resetTimer()"
            >
              {{
                timeLeft === '0:00'
                  ? `${$t('didnt_get_the_code')}`
                  : timeLeft + 'sn.'
              }}
            </span>
          </TheTimeCountDown>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheTimeCountDown from '@/components/TheTimeCountDown'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  name: 'TheLoginVerifyCode',

  props: {
    userIdd: {
      type: Number,
      required: true
    },
    phone: {
      type: Number,
      required: true
    },
    otherUserId: {
      type: Number,
      default: 0
    },
    isChooseYourVehicle: {
      type: Boolean
    },
    setToken: {
      type: Boolean
    },
    formData: {
      type: []
    },
    selectedCarPriceId: {
      type: String
    },
    isUserInformationForm: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      required: false
    }
  },
  mounted() {
    if (this.code) {
      this.otp = this.code
    }
  },
  components: {
    TheButton,

    TheTimeCountDown
  },
  data() {
    return {
      loading: false,
      otp: null,
      timerFalse: false,
      timeLeft: null,
      form: {
        phone: null
      }
    }
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      getGenerate: 'auth/getGenerate',
      getRegister: 'auth/getRegister'
    })
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    },
    didntGetTheCode() {
      if (this.getGenerate) {
        this.form.phone = this.getGenerate.phone
      } else {
        this.form.phone = this.getRegister.phone
      }

      this.generate(this.form)
        .then((data) => {
          if (data !== null && data.user_id !== null) {
            this.userId = data.user_id
          }

          this.loading = false
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 400) {
              this.$router.replace({
                name: 'MyAccount'
              })
            }
            // ✅ log status code here
          }
        })
    },
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    ...mapActions({
      signIn2: 'auth/signIn2',
      signIn: 'auth/signIn', //otp login
      submitBookingAction: 'booking/submitBooking',
      setIndexAction: 'bookingStep/setIndex',
      generate: 'auth/generate'
    }),

    prewVerify() {
      this.$emit('prewVerify')
    },
    //Eğer login ekranına gelmeden myAccount a gelir ise alış veriş sayfasından
    async signInOtp() {
      this.loading = true

      let data = {
        otp: this.otp,
        user_id: this.userIdd
      }

      if (this.setToken) {
        var loginResponse = await this.signIn2(data)

        if (loginResponse.status == 200) {
          const dataBooking = {}

          const booking = this.$store.state.booking
          dataBooking.other_user_id = this.otherUserId
          dataBooking.km = Math.ceil(booking.distance / 1000)
          dataBooking.duration = Math.ceil(booking.duration / 60)
          dataBooking.from = booking.origin.postal_code
          dataBooking.to = booking.destination.postal_code
          dataBooking.delivery_type = booking.delivery_type_id
          dataBooking.pickup_date = booking.pickup_date
          dataBooking.delivery_date = booking.delivery_date
          dataBooking.from_lat = booking.origin.lat
          dataBooking.from_lng = booking.origin.lng
          dataBooking.to_lat = booking.destination.lat
          dataBooking.to_lng = booking.destination.lng
          dataBooking.to_address = booking.destination.address
          dataBooking.to_name = booking.destination.name
          dataBooking.from_address = booking.origin.address
          dataBooking.from_name = booking.origin.name

          if (this.selectedCarPriceId) {
            dataBooking.price_id = Number(this.selectedCarPriceId)
          } else {
            dataBooking.price_id = -1
          }

          const list = []
          dataBooking['list'] = list
          this.submitBookingAction(dataBooking)
            .then((bookingId) => {
              // this.setStep(4)
              if (this.selectedCarPriceId) {
                this.setStep(4)
              } else {
                this.$router.push({
                  path: `/reservations/${bookingId}`
                })
              }

              this.submitStatus = 'OK'
            })
            .catch(() => {
              // this.setStep(4)
            })
            .finally(() => {
              this.loading = false
            })
        }
        return
      }

      this.signIn(data)
        .then(async () => {
          //Km bulunamayınca teklif formundan login ol
          if (this.isChooseYourVehicle) {
            this.submitStatus = 'PENDING'
            const data = {}

            const booking = this.$store.state.booking
            data.km = Math.ceil(booking.distance / 1000)
            data.duration = Math.ceil(booking.duration / 60)
            data.from = booking.origin.postal_code
            data.to = booking.destination.postal_code
            data.delivery_type = booking.delivery_type_id
            data.pickup_date = booking.pickup_date
            data.delivery_date = booking.delivery_date
            data.from_lat = booking.origin.lat
            data.from_lng = booking.origin.lng
            data.to_lat = booking.destination.lat
            data.to_lng = booking.destination.lng
            data.to_address = booking.destination.address
            data.to_name = booking.destination.name
            data.from_address = booking.origin.address
            data.from_name = booking.origin.name
            data.booking_date = this.format_date(booking.fromDate)
            data.booking_time = this.format_time(booking.fromTime)
            if (this.selectedCarPriceId) {
              data.price_id = this.selectedCarPriceId
            } else {
              data.price_id = -1
            }

            const list = []
            data['list'] = list
            await this.submitBookingAction(data)
              .then((bookingId) => {
                if (this.selectedCarPriceId) {
                  this.setStep(4)
                } else {
                  this.$router.push({
                    path: `/reservations/${bookingId}`
                  })
                }

                this.submitStatus = 'OK'
                this.loading = false
              })
              .catch(() => {
                if (this.selectedCarPriceId) {
                  this.setStep(4)
                } else {
                  this.$router.push({
                    name: 'Booking.Result',
                    query: { status: 'error' }
                  })
                }

                this.loading = false
              })
          } else {
            this.$router.replace({
              name: 'Booking'
            })
          }

          this.verification_code = false

          this.loading = false
        })
        .catch(() => {
          Swal.fire({
            text: this.$t('errors.login_failed_description'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.verify-code-title {
  text-align: left;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight: normal;
}

.change-phone-number {
  text-align: right;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #707070 !important;
  opacity: 1;
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
}
</style>
