<template>
  <input
    :type="type"
    :tabindex="tabindex"
    :value="value"
    :placeholder="this.placeholder"
    class="textbox"
    v-on:input="$emit('update:value', $event.target.value)"
  />
</template>

<script lang="js">
export default {
  name: 'TheTextbox',

  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: String,
    tabindex: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  emits: ["update:value"],
}
</script>

<style scoped>
.textbox {
  border: 1px solid rgb(var(--border-color));
  color: rgb(var(--text-color));
  border-radius: 4px;
  font-size: 1rem;
  padding: 15px 10px;
}
</style>
