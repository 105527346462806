<template>
  <div class="px-0">
    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="''"
      v-bind:description="this.contracts"
      @close="logChange"
    />

    <!-- <div class="row" v-if="this.authenticated">
      <div class="col-12 d-flex juistify-content-start">
        <div class="form-check mr-3 d-flex align-items-center mb-5">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="im_booking_for_someone"
            :disabled="!this.authenticated"
            @change="onIsImBookingForSomeoneChange"
            v-model="IsImBookingForSomeone"
          />
          <label
            class="form-check-label d-inline-block"
            for="im_booking_for_someone"
          >
            {{ $t('Im_booking_for_someone_else') }}
          </label>
        </div>
        <hr />
      </div>
    </div> -->

    <div class="register-container">
      <div
        class="verification_code col-lg-12 col-md-12"
        v-if="myAccountVerifyCode"
      >
        <TheMyAccountVerifyCode
          v-bind:setToken="setToken"
          v-bind:formData="form"
          v-bind:myAccountSelectedCarPriceId="selectedCar.price_id"
          v-bind:phone_number="form.phone_number"
          v-bind:isChooseYourVehicle="isRegisterChooseYourVehicle"
          v-bind:isUserInformationForm="true"
          @prewVerify="verifyReset"
          :code="this.code"
        ></TheMyAccountVerifyCode>
      </div>
      <div
        class="verification_code col-lg-12 col-md-12"
        v-if="verification_code"
      >
        <TheLoginVerifyCode
          v-bind:setToken="setToken"
          v-bind:selectedCarPriceId="selectedCar.price_id"
          v-bind:formData="form"
          v-bind:isChooseYourVehicle="isLoginChooseYourVehicle"
          v-bind:userIdd="userId"
          v-bind:isUserInformationForm="true"
          @prewVerify="verifyReset"
          :otherUserId="otherUserId"
          :code="this.code"
        ></TheLoginVerifyCode>
      </div>
      <div v-if="registerForm">
        <form @submit.prevent="submit" class="register">
          <div class="form-row">
            <div class="col-md-12 col-lg-6">
              <div
                class="form-group form-item text-left"
                :class="{ 'form-group--error': $v.form.name.$error }"
              >
                <TheLabel class="text-left"
                  >{{ $t('name') }}
                  <span class="error text-danger" v-if="!$v.form.name.required">
                    *
                  </span>
                </TheLabel>

                <input
                  class="form-control"
                  type="text"
                  v-model="$v.form.name.$model"
                  :placeholder="$t('name')"
                  @input="onNameChange"
                  :disabled="
                    IsImBookingForSomeone == false && this.authenticated == true
                  "
                />
                <span
                  class="error text-danger"
                  v-if="!$v.form.name.required && $v.form.name.$dirty"
                >
                  {{ $t('value_is_required') }}
                </span>
              </div>
            </div>

            <div class="col-md-12 col-lg-6">
              <div
                class="form-group form-item text-left"
                :class="{ 'form-group--error': $v.form.sur_name.$error }"
              >
                <TheLabel class="text-left"
                  >{{ $t('surname') }}
                  <span
                    class="error text-danger"
                    v-if="!$v.form.sur_name.required"
                  >
                    *
                  </span>
                </TheLabel>

                <input
                  class="form-control"
                  type="text"
                  v-model="$v.form.sur_name.$model"
                  :placeholder="$t('surname')"
                  @input="onSurnameChange"
                  :disabled="
                    IsImBookingForSomeone == false && this.authenticated == true
                  "
                />

                <span
                  class="error text-danger"
                  v-if="!$v.form.sur_name.required && $v.form.sur_name.$dirty"
                >
                  {{ $t('value_is_required') }}
                </span>
              </div>
            </div>

            <div class="col-md-12 col-lg-6">
              <div
                class="form-group form-item text-left"
                :class="{ 'form-group--error': $v.form.e_mail.$error }"
              >
                <TheLabel class="text-left"
                  >{{ $t('email') }}
                  <span
                    class="error text-danger"
                    v-if="!$v.form.e_mail.required"
                  >
                    *
                  </span>
                </TheLabel>

                <input
                  class="form-control"
                  type="text"
                  v-model="$v.form.e_mail.$model"
                  @input="onEmailChange"
                  :placeholder="$t('email')"
                  :disabled="
                    IsImBookingForSomeone == false && this.authenticated == true
                  "
                />

                <span
                  class="error text-danger"
                  v-if="!$v.form.e_mail.required && $v.form.e_mail.$dirty"
                >
                  {{ $t('value_is_required') }}
                </span>
              </div>
            </div>

            <div class="col-md-12 col-lg-6">
              <div class="form-group form-item text-left">
                <TheLabel class="text-left">{{ $t('phone') }} </TheLabel>
                <vue-tel-input
                  :disabled="
                    IsImBookingForSomeone == false && this.authenticated == true
                  "
                  v-bind="this.bindProps"
                  v-model="form.phone_number"
                  class="tel-input"
                  :placeholder="$t('phone')"
                  v-on:country-changed="countryChanged"
                  :defaultCountry="defaultCountry"
                  :key="componentKey"
                  @validate="validatePhone"
                ></vue-tel-input>

                <span class="error text-danger" v-if="phoneIsValid === false">
                  {{ $t('phone_is_not_valid') }}
                </span>
              </div>
            </div>

            <br />
            <div
              class="d-flex justify-content-start align-items-start flex-column"
              v-if="IsImBookingForSomeone || !this.authenticated"
            >
              <div
                v-for="(item, index) in form.privacyArray"
                :key="index"
                class="privacy-item"
              >
                <div class="form-item form-check mr-3 mb-0">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="privacy"
                    v-model="item.selected"
                  />
                  <span class="form-check-label d-inline-block" for="privacy">
                    <a
                      href="javascript:;"
                      :id="item.id"
                      v-on:click="privacyDetail(item.id, $event)"
                      >{{ item.prefix }} {{ item.name }} {{ item.suffix }}.</a
                    >
                  </span>
                </div>
                <span
                  class="privacy-item-error error text-danger d-flex"
                  v-if="
                    item.selected !== undefined &&
                    !$v.form.privacyArray.$each[index].selected.required &&
                    $v.form.privacyArray.$each[index].selected.$dirty
                  "
                >
                  {{ $t('value_is_required') }}
                </span>
              </div>
            </div>

            <div class="col-12">
              <div class="controls mt-5">
                <TheButton
                  reverse="reverse"
                  size="btn-sm"
                  @click="deccrementStepCount"
                  :disabled="step == 1"
                  >{{ $t('back') }}
                </TheButton>

                <TheButton :loading="loading" customClass="ml-3" size="btn-sm"
                  >{{ $t('next_step') }}
                </TheButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import TheButton from '@/components/TheButton'
import Swal from 'sweetalert2'
import { required } from 'vuelidate/lib/validators'
import TheLabel from '@/components/TheLabel'
import { mapGetters, mapActions } from 'vuex'
import TheMyAccountVerifyCode from '@/components/TheMyAccountVerifyCode'
import TheLoginVerifyCode from '@/components/TheLoginVerifyCode'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import moment from 'moment'
import TheModal from '@/components/TheModal'
import axios from 'axios'
import countries from '../assets/telInputcountryData.js'

export default {
  name: 'Register',
  components: {
    TheButton,
    TheLabel,
    TheMyAccountVerifyCode,
    TheLoginVerifyCode,
    VueTelInput,
    TheModal
  },
  data() {
    return {
      phoneIsValid: undefined,
      showModal: false,
      contracts: null,
      countries: countries,
      code: '',
      IsImBookingForSomeone: false,
      isLoginChooseYourVehicle: false,
      isRegisterChooseYourVehicle: false,
      registerForm: true,
      verification_code: false,
      loginVerification_code: false,
      myAccountVerifyCode: false,
      setToken: false,
      userId: '',
      otherUserId: 0,
      form: {
        country_code: '',
        name: '',
        sur_name: '',
        e_mail: '',
        phone_number: '',

        name2: '',
        sur_name2: '',
        e_mail2: '',
        phone_number2: '',

        name3: '',
        sur_name3: '',
        e_mail3: '',
        phone_number3: '',
        privacyArray: []
      },
      loading: false,
      user: false,
      bindProps: {
        autofocus: false,
        autoFormat: true,
        inputOptions: {
          showDialCode: true
        }
      },
      defaultCountry: 'CH',
      componentKey: 0 // initial key
    }
  },
  mounted() {
    var lang = 'en'
    if (localStorage.getItem('lang')) {
      lang = JSON.parse(localStorage.getItem('lang'))
    }

    axios
      .get('api/contracts/active?lang=' + lang)
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          for (const item of response.data) {
            if (item.position === 'register') {
              this.form.privacyArray.push(item)
            }
          }
        }
      })
      .catch((error) => {
        this.errorMessage = error.message
        console.error('There was an error!', error)
      })
    const user = this.$store.state.auth.user
    if (user) {
      this.user = user
      this.form.e_mail = user.email
      this.form.name = user.name
      this.form.sur_name = user.surname
      this.form.phone_number = user.country_code + user.phone
      this.form.userId = user.user_id
      this.form.user_type = user.type
      this.form.e_mail3 = user.email
      this.form.name3 = user.name
      this.form.sur_name3 = user.surname
      this.form.phone_number3 = user.country_code + user.phone
      this.form.country_code = user.country_code
      const foundCountry = this.countries.find(
        (country) => country.dial_code === user.country_code
      )

      this.defaultCountry = foundCountry ? foundCountry.code : 'CH'
      this.componentKey++
    }
  },

  validations: {
    form: {
      name: { required },
      sur_name: { required },
      e_mail: { required },
      privacyArray: {
        $each: {
          selected: {
            required: function (val, item) {
              if (
                item.required &&
                this.IsImBookingForSomeone == this.authenticated
              ) {
                return val
              }
              return true
            }
          }
        }
      }
    }
  },

  methods: {
    validatePhone(object) {
      this.phoneIsValid = object.valid
    },
    getCodeFromDial(dial_code) {
      const foundCountry = this.countries.find(
        (country) => country.dial_code === dial_code
      )
      return foundCountry ? foundCountry.code : 'CH'
    },
    countryChanged(country) {
      this.form.country_code = country.dialCode
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    logChange(event) {
      this.showModal = event
    },

    verifyReset() {
      this.verification_code = false
      this.loginVerification_code = false
      this.myAccountVerifyCode = false
      this.registerForm = true
    },
    phoneChanged(text) {
      console.log(text)
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    },
    privacyDetail(id, event) {
      console.log(id)
      console.log(event)
      this.showModal = true
      this.contracts = 'Loading...'

      // var baseUrl = process.env.VUE_APP_API_BASE_URL
      // this.iframe.src = `${baseUrl}api/payment?bookingId=${
      //   this.bookingId
      // }&paymentType=${this.fullPayment ? 'Full' : 'Pre'}`

      axios
        .get(
          `api/contracts/active/${id}&payment?bookingId=${
            this.bookingId
          }&paymentType=${this.fullPayment ? 'Full' : 'Pre'}`
        )
        .then((response) => (this.contracts = response.data))
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    onSurnameChange(event) {
      if (this.IsImBookingForSomeone) {
        this.form.sur_name2 = event.target.value
      } else {
        this.form.sur_name3 = event.target.value
      }
    },
    onNameChange(event) {
      if (this.IsImBookingForSomeone) {
        this.form.name2 = event.target.value
      } else {
        this.form.name3 = event.target.value
      }
    },
    onEmailChange(event) {
      if (this.IsImBookingForSomeone) {
        this.form.e_mail2 = event.target.value
      } else {
        this.form.e_mail3 = event.target.value
      }
    },
    onPhoneChange(event) {
      if (this.IsImBookingForSomeone) {
        this.form.phone_number2 = event.target.value
      } else {
        this.form.phone_number3 = event.target.value
      }
    },
    onIsImBookingForSomeoneChange() {
      if (this.IsImBookingForSomeone) {
        this.form.e_mail = this.form.e_mail2
        this.form.name = this.form.name2
        this.form.sur_name = this.form.sur_name2
        this.form.phone_number = this.form.phone_number2
        this.defaultCountry = 'CH'
        this.componentKey++
      } else {
        this.form.e_mail = this.form.e_mail3
        this.form.name = this.form.name3
        this.form.sur_name = this.form.sur_name3
        this.form.phone_number = this.form.phone_number3
        const user = this.$store.state.auth.user
        const foundCountry = this.countries.find(
          (country) => country.dial_code === user.country_code
        )

        this.defaultCountry = foundCountry ? foundCountry.code : 'CH'
        this.componentKey++
      }
    },
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    async submit() {
      this.$v.$touch()

      if (this.phoneIsValid !== true) {
        this.phoneIsValid = false
      }

      if (this.$v.$invalid || this.phoneIsValid !== true) {
        this.submitStatus = 'ERROR'
      } else {
        if (this.authenticated) {
          //Numara farklı ise kontrole geç
          if (
            this.formatPhoneNumber(
              this.form.phone_number,
              `+${this.form.country_code}`
            ) !== this.$store.state.auth.user.phone
          ) {
            //Phone kontrolü
            this.IsImBookingForSomeone = true
            this.setToken = true
            this.loading = true
            const payload = {
              name: this.form.name,
              surname: this.form.sur_name,
              email: this.form.e_mail,
              phone: this.formatPhoneNumber(
                this.form.phone_number,
                `+${this.form.country_code}`
              ),
              country_code: `+${this.form.country_code}`
            }

            await this.generate(payload)
              .then(async (data) => {
                if (data !== null && data.user_id !== null) {
                  this.userId = data.user_id
                  this.code = data.otp
                  this.otherUserId = data.user_id
                  this.verification_code = true
                  this.isLoginChooseYourVehicle = true
                  this.registerForm = false
                }
              })
              .catch(async (err) => {
                if (err.response) {
                  if (err.response.status == 400) {
                    const data = {
                      name: this.form.name,
                      surname: this.form.sur_name,
                      email: this.form.e_mail,
                      phone: this.formatPhoneNumber(
                        this.form.phone_number,
                        `+${this.form.country_code}`
                      ),
                      country_code: `+${this.form.country_code}`,
                      password: 'password'
                    }

                    let registerResponse = await this.register2(
                      JSON.parse(JSON.stringify(data))
                    )

                    if (registerResponse.status == 200) {
                      if (registerResponse != null && registerResponse.otp) {
                        this.code = registerResponse.otp.toString()
                      }

                      if (registerResponse.data.user_id) {
                        this.userId = registerResponse.user_id
                        this.verification_code = false
                        this.loginVerification_code = true
                        this.registerForm = false
                      } else {
                        this.registerResponsePhone = registerResponse.data.phone
                        this.myAccountVerifyCode = true
                        this.isRegisterChooseYourVehicle = true
                        this.registerForm = false
                      }
                    } else if (registerResponse.status == 400) {
                      var error = registerResponse.data.error.map(function (q) {
                        return `${q} <br/>`
                      })
                      await Swal.fire({
                        title: this.$t('errors.login_failed'),
                        html: error,
                        icon: 'error',
                        confirmButtonText: this.$t('close')
                      })
                    }
                    return
                  }
                  // ✅ log status code here
                  console.log(err.response.status)
                  console.log(err.message)
                  console.log(err.response.headers)
                  console.log(err.response.data)
                }

                this.verification_code = false
              })
              .finally(() => {
                this.loading = false
              })
            return
          }

          this.submitStatus = 'PENDING'
          const data = {}

          const booking = this.$store.state.booking

          data.other_user_id = this.otherUserId
          data.km = Math.ceil(booking.distance / 1000)
          data.duration = Math.ceil(booking.duration / 60)
          data.from = booking.origin.postal_code
          data.to = booking.destination.postal_code
          data.delivery_type = booking.delivery_type_id
          data.pickup_date = booking.pickup_date
          data.delivery_date = booking.delivery_date
          data.from_lat = booking.origin.lat
          data.from_lng = booking.origin.lng
          data.to_lat = booking.destination.lat
          data.to_lng = booking.destination.lng
          data.to_address = booking.destination.address
          data.to_name = booking.destination.name
          data.from_address = booking.origin.address
          data.from_name = booking.origin.name
          data.price_id = this.selectedCar.price_id
          data.booking_date = this.format_date(booking.fromDate)
          data.booking_time = this.format_time(booking.fromTime)
          const list = []
          data['list'] = list
          this.submitBookingAction(data)
            .then((track_code) => {
              this.setStep(4)
              console.log('success: ' + track_code)

              // this.$router.push({
              //   name: 'Booking.Result',
              //   query: { status: 'success', code: track_code }
              // })
              this.submitStatus = 'OK'
              this.loading = false
            })
            .catch(() => {
              this.setStep(4)
              console.log('error')
              // this.$router.push({
              //   name: 'Booking.Result',
              //   query: { status: 'error' }
              // })
              this.loading = false
            })
        } else {
          this.loading = true
          const data = {
            name: this.form.name,
            surname: this.form.sur_name,
            email: this.form.e_mail,
            phone: this.formatPhoneNumber(
              this.form.phone_number,
              `+${this.form.country_code}`
            ),
            country_code: `+${this.form.country_code}`
          }

          this.generate(data)
            .then(async (data) => {
              if (data !== null && data.user_id !== null) {
                this.userId = data.user_id
                this.code = data.otp

                this.verification_code = true
                this.isLoginChooseYourVehicle = true
                this.registerForm = false
              }
              this.loading = false
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 400) {
                  const data = {
                    name: this.form.name,
                    surname: this.form.sur_name,
                    email: this.form.e_mail,
                    phone: this.formatPhoneNumber(
                      this.form.phone_number,
                      `+${this.form.country_code}`
                    ),
                    country_code: `+${this.form.country_code}`,
                    password: 'password'
                  }

                  this.register(data)
                    .then((response) => {
                      response = JSON.parse(response)

                      if (response != null && response.otp) {
                        this.code = response.otp.toString()
                      }

                      if (response.user_id) {
                        this.userId = response.user_id
                        this.verification_code = false
                        this.loginVerification_code = true
                        this.registerForm = false
                      } else {
                        this.registerResponsePhone = response.phone
                        this.myAccountVerifyCode = true
                        this.isRegisterChooseYourVehicle = true
                        this.registerForm = false
                      }
                    })
                    .catch((err) => {
                      console.log(err.response)
                      Swal.fire({
                        title: this.$t('errors.login_failed'),
                        text: err.response.data.error,
                        icon: 'error',
                        confirmButtonText: this.$t('close')
                      })
                      this.loading = false
                    })
                }
                // ✅ log status code here
                console.log(err.response.status)
                console.log(err.message)
                console.log(err.response.headers)
                console.log(err.response.data)
              }

              ;(this.loading = false), (this.verification_code = false)
            })
        }
      }
    },
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      var incrementStep = this.step
      incrementStep--
      this.setIndexAction(incrementStep)
    },
    ...mapActions({
      register: 'auth/register',
      submitBookingAction: 'booking/submitBooking',
      generate: 'auth/generate',
      setIndexAction: 'bookingStep/setIndex',
      register2: 'auth/register2'
    })
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      authenticated: 'auth/authenticated',
      selectedCar: 'booking/getSelectedCar'
    })
  }
}
</script>

<style scoped>
.privacy-item {
  margin-left: 5px;
}
</style>
