<template>
  <div class="my-bookings-container">
    <div class="my-bookings-header">
      <h1>{{ $t('my_bookings') }}</h1>
    </div>
    <div>
      <vuetable
        ref="vuetable"
        :css="{
          tableClass: 'table table-striped table-hover',
          loadingClass: $t('please_wait')
        }"
        :api-mode="false"
        :fields="fields"
        :per-page="perPage"
        :data-manager="dataManager"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
      >
        <div slot="actions" slot-scope="props">
          <button @click="onActionClicked(props.rowData.id)">
            {{ $t('detail') }}
          </button>
        </div>
      </vuetable>
      <div style="padding-top: 10px">
        <vuetable-pagination
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {
    Vuetable,
    VuetablePagination
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  data() {
    return {
      fields: [
        {
          name: 'track_code',
          title: this.$t('Reservation_no'),
          sortField: 'track_code',
          visible: true
        },
        { name: 'from_name', title: this.$t('from'), sortField: 'from_name' },
        { name: 'to_name', title: this.$t('to'), sortField: 'to_name' },
        { name: 'status_name', title: this.$t('status'), sortField: 'status' },
        {
          name: 'created_at',
          title: this.$t('create_date'),
          sortField: 'created_at',
          formatter: (value) => {
            return moment(value).format('DD.MM.YYYY HH:mm')
          }
        },
        {
          name: 'payment_status',
          title: this.$t('payment_status'),
          sortField: 'payment_status',
          formatter: (value) => {
            return value ?? 'Not Paid'
          }
        },
        'actions'
      ],
      perPage: 10,
      data: []
    }
  },

  watch: {
    data() {
      this.$refs.vuetable.refresh()
    }
  },

  mounted() {
    axios.get('api/getBookings').then((response) => {
      this.data = response.data
    })

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.onResize()
  },

  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return

      let local = this.data

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction)
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      )
      let from = pagination.from - 1
      let to = from + this.perPage

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      }
    },
    onActionClicked(id) {
      this.$router.push({ name: 'MyBookingsDetail', params: { id } })
    },
    onResize() {
      if (window.innerWidth < 800) {
        this.fields[1].visible = false
        this.fields[2].visible = false
        this.fields[4].visible = false
        this.fields[5].visible = false
      } else {
        this.fields[1].visible = true
        this.fields[2].visible = true
        this.fields[4].visible = true
        this.fields[5].visible = true
      }
      this.$refs.vuetable.normalizeFields()
    }
  }
}
</script>

<style lang="scss">
@import 'https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.14/semantic.min.css';

.my-bookings-container {
  .my-bookings-header {
    h1 {
      color: rgb(var(--primary-color));
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 152px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.sort-icon {
  display: none !important;
}
.table thead th {
  white-space: pre !important;
}
.vuetable-body {
  color: hsla(0, 0%, 13%, 1);
  text-align: left;
  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}
.ui.pagination.menu .active.item,
.ui.pagination.menu .item,
.ui.pagination.menu .item {
  text-align: left !important;
  font: normal normal normal 13px/20px Poppins !important;
  letter-spacing: 0px;
  opacity: 1;
}
.ui.pagination.menu .icon.item i.icon {
  font-size: 10px;
}
</style>
