<template>
  <div class="button-container" :class="{ 'full-width': fullwidth }">
    <button
      :type="type"
      @click="click"
      class="button"
      :disabled="disabled"
      :class="[size, color, reverse, customClass, { loading: loading }]"
    >
      <slot />
    </button>
    <div v-if="loading" class="lds-dual-ring"></div>
  </div>
</template>

<script lang="js">
export default {
  name: 'TheButton',
  methods: {
    click() {
      if (this.loading) return
      this.$emit('click')
    }
  },
  props: {
      customClass: {
      type: String,
      default: ''
    },
    reverse: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'btn-md'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    fullwidth: Boolean,
    loading: Boolean,
    color: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  position: relative;
  display: inline-block;
}
.button {
  color: rgb(var(--white-color));
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}
.button[disabled='disabled'] {
  opacity: 0.5;
}
.btn-md {
  padding: 15px 30px;
  font-size: 1.2rem;
}

.btn-sm {
  padding: 10px 30px;
  font-size: 1rem;
}

.red {
  background-color: rgb(var(--danger-color));
  border: 1px solid rgb(var(--danger-color));
}

.default {
  background-color: rgb(var(--secondary-color));
  border: 1px solid rgb(var(--secondary-color));
}
.default.reverse {
  color: rgb(var(--secondary-color));
  background-color: transparent;
  border: 1px solid rgb(var(--secondary-color));
  &:hover {
    color: rgb(var(--white-color));
    background-color: rgb(var(--secondary-color));
    border: 1px solid rgb(var(--secondary-color));
  }
}

.disabled {
  background-color: #e6e6e6;
  color: #959595;
}

.loading {
  opacity: 0.2;
}

.full-width {
  width: 100%;
  button {
    width: 100%;
  }
}

.lds-dual-ring {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.lds-dual-ring:after {
  display: block;
  content: ' ';
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid rgb(var(--primary-color));
  border-color: rgb(var(--primary-color)) transparent rgb(var(--primary-color))
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
