import axios from 'axios'

export default {
  namespaced: true,

  state: {
    settings: null
  },

  getters: {
    getSettings(state) {
      return state.settings
    },

    getSettingByCode(state) {
      return (code) => {
        return state.settings?.[code] ? state.settings[code] : null
      }
    }
  },

  mutations: {
    SET_SETTINGS(state, data) {
      state.settings = data
    }
  },

  actions: {
    async setSettings({ commit }) {
      try {
        const config = {
          method: 'get',
          url: 'api/settings'
        }
        const response = await axios(config)
        commit('SET_SETTINGS', response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

// export default {
//   namespaced: true,

//   state: {
//     settings: null
//   },

//   getters: {
//     getSettings(state) {
//       return state.settings
//     }
//   },

//   mutations: {
//     SET_SETTINGS(state, data) {
//       state.settings = data
//     }
//   },
//   actions: {
//     setSettings({ commit }) {
//       const config = {
//         method: 'get',
//         url: 'api/settings'
//       }
//       axios(config)
//         .then(function (response) {
//           commit('SET_SETTINGS', response.data)
//         })
//         .catch(function (error) {
//           console.log(error)
//         })
//     }
//   }
// }
