<template>
  <div>
    <div v-if="!IsTheCalculation" class="row">
      <div class="col-md-12 col-lg-6">
        <div
          class="form-group d-flex justify-content-start flex-column align-items-start mt-4"
        >
          <TheLabel>{{ $t('from') }}</TheLabel>
          <GmapAutocomplete
            ref="origin"
            :placeholder="$t('airport_address_station_port')"
            :selectFirstOnEnter="true"
            :value="this.origin.name"
            :options="{
              bounds: bounds,
              strictBounds: true
            }"
            @place_changed="setOrigin"
          />
          <span
            class="error text-danger"
            v-if="!$v.origin.name.required && $v.origin.name.$dirty"
          >
            {{ $t('value_is_required') }}
          </span>
        </div>
      </div>

      <div class="col-md-12 col-lg-6">
        <div
          class="form-group d-flex justify-content-start flex-column align-items-start mt-4"
        >
          <TheLabel>{{ $t('to') }}</TheLabel>
          <GmapAutocomplete
            ref="destination"
            :placeholder="$t('airport_address_station_port')"
            :selectFirstOnEnter="true"
            :value="this.destination.name"
            :options="{
              bounds: bounds,
              strictBounds: true
            }"
            @place_changed="setDestination"
          />

          <span
            class="error text-danger"
            v-if="!$v.destination.name.required && $v.destination.name.$dirty"
          >
            {{ $t('value_is_required') }}
          </span>
        </div>
      </div>

      <div class="col-md-12 col-lg-6">
        <div
          class="form-group d-flex justify-content-start flex-column align-items-start mt-4"
        >
          <TheLabel>{{ $t('date') }}</TheLabel>

          <date-picker
            :disabled-date="disabledBeforeTodayAndAfter90Days"
            format="DD.MM.YYYY"
            type="date"
            v-model="fromDate"
            v-bind:class="{
              error: v$.fromDate.$error,
              valid: v$.fromDate.$dirty
            }"
            placeholder="Select date"
            class="pac-target-input-date"
          ></date-picker>

          <span
            class="error text-danger"
            v-if="!$v.fromDate.required && $v.fromDate.$dirty"
          >
            {{ $t('value_is_required') }}
          </span>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div
          class="form-group d-flex justify-content-start flex-column align-items-start mt-4"
        >
          <TheLabel>{{ $t('time') }}</TheLabel>

          <date-picker
            :disabled-time="disabledTime"
            format="HH:mm"
            type="time"
            :minute-step="15"
            v-model="v$.time.$model"
            placeholder="Select time"
            class="pac-target-input-date"
          ></date-picker>

          <span
            class="error text-danger"
            v-if="!$v.time.required && $v.time.$dirty"
          >
            {{ $t('value_is_required') }}
          </span>
        </div>
      </div>
    </div>
    <!-- <TheCalculation v-if="IsTheCalculation" /> -->
    <div class="controls mt-2">
      <TheButton
        v-if="IsTheCalculation"
        customClass="ml-3"
        size="btn-sm"
        @click="incrementStepCount"
        >{{ $t('next_step') }}
      </TheButton>

      <!-- Hesaplama işlemi -->
      <TheButton
        v-if="!IsTheCalculation"
        customClass="ml-3"
        size="btn-sm"
        @click="submit"
        >{{ $t('next_step') }}
      </TheButton>
    </div>
  </div>
</template>
<script>
import TheLabel from '@/components/TheLabel'
import TheButton from '@/components/TheButton'
// import TheCalculation from '@/components/TheCalculation'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
// import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { useVuelidate } from '@vuelidate/core'
import { required } from 'vuelidate/lib/validators'

export default {
  created() {
    // this.getPosts(),

    this.$store.dispatch('homeSettings/setSettings').then(() => {
      this.timerule =
        this.$store.getters['homeSettings/getSettingByCode']('booking_time')
    })
  },

  data() {
    return {
      $invalid: true,
      $dirty: false,
      $error: false,
      $pending: false,
      timerule: null,
      message: '',
      fromDate: null,
      isBookNow: true,
      loading: false,
      strikeColor: '#0fa1f7',
      strokeOpacity: 0.7,
      strokeWeight: 8,
      polyline_points: null,
      distance: null,
      duration: null,
      packages: [],
      user_type: null,
      IsTheCalculation: false,
      time: '',
      origin: {
        name: '',
        address: null,
        postal_code: null,
        lat: null,
        lng: null
      },
      destination: {
        name: '',
        address: null,
        postal_code: null,
        lat: null,
        lng: null
      },
      bounds: {
        north: 49.7792,
        south: 45.2726,
        east: 11.7759,
        west: 5.316
      }
    }
  },
  components: {
    TheLabel,
    TheButton,
    // TheCalculation,
    DatePicker
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      fromDate: { required },
      time: { required },
      destination: {
        name: { required }
      },
      origin: {
        name: { required }
      }
    }
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex'
    }),
    getDistance: function () {
      return `${Math.ceil(this.distance / 1000)} km`
    },
    getDuration: function () {
      return `${Math.ceil(this.duration / 60)} min`
    }
  },
  mounted() {
    const booking = this.$store.state.booking
    if (booking.origin) {
      this.origin.name = booking.origin.name
      this.destination.name = booking.destination.name
      this.fromDate = booking.fromDate
      this.time = booking.fromTime
    }
  },

  methods: {
    disabledBeforeTodayAndAfter90Days(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const after90Days = new Date()
      after90Days.setDate(today.getDate() + 90)
      after90Days.setHours(0, 0, 0, 0)
      return date < today || date > after90Days
    },
    disabledBeforeTodayAndAfterAWeek(fromDate) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return fromDate < today
    },
    disabledTime(time) {
      if (this.fromDate > time) {
        return false
      }

      var date = this.nearestDate()

      if (time < date) {
        return true
      } else {
        return false
      }
    },
    nearestDate() {
      var date = new Date()
      date = date.setHours(date.getHours() + Number(this.timerule.value))
      return date
    },
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      var incrementStep = this.step
      incrementStep--
      this.setIndexAction(incrementStep)
    },
    ...mapActions({
      setIndexAction: 'bookingStep/setIndex',
      setDistanceAction: 'booking/setDistance',
      setRouteAction: 'booking/setRoute',
      setUserTypeAction: 'booking/setUserType',
      clearState: 'booking/clearState',
      setFromDateAction: 'booking/setFromDate',
      setFromTimeAction: 'booking/setFromTime'
    }),
    setOrigin(data) {
      this.origin.name = this.$refs.origin.$refs.input.value
      this.origin.address = data.formatted_address
      const postal_code = data.address_components.find((element) =>
        element.types.find((type) => type === 'postal_code')
      )
      if (postal_code !== undefined) {
        this.origin.postal_code = postal_code.long_name.replace(/\s/g, '')
      }
      this.origin.lat = data.geometry.location.lat()
      this.origin.lng = data.geometry.location.lng()
    },
    setDestination(data) {
      this.destination.name = this.$refs.destination.$refs.input.value
      this.destination.address = data.formatted_address
      const postal_code = data.address_components.find((element) =>
        element.types.find((type) => type === 'postal_code')
      )
      if (postal_code !== undefined) {
        this.destination.postal_code = postal_code.long_name.replace(/\s/g, '')
      }

      this.destination.lat = data.geometry.location.lat()
      this.destination.lng = data.geometry.location.lng()
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        return false
      }

      // const isFormCorrect = await this.v$.$validate()
      // // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      // if (!isFormCorrect) return
      // // actually submit form

      if (
        !this.origin ||
        !this.destination ||
        !this.origin.name ||
        !this.destination.name
      ) {
        {
          Swal.fire({
            title: this.$t('errors.invalid_address'),
            text: this.$t('errors.enter_correct_address'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
        }

        return
      }

      // if (!this.authenticated && this.user_type === '') {
      //   Swal.fire({
      //     title: this.$t('errors.select_user_type'),
      //     text: this.$t('errors.select_user_type_description'),
      //     icon: 'error',
      //     confirmButtonText: this.$t('close')
      //   })
      //   return
      // }

      this.loading = true
      const payload = {
        origin: this.origin,
        destination: this.destination
      }

      const user_type = {
        user_type: this.user_type
      }

      this.setRouteAction(payload)
      this.setUserTypeAction(user_type)
      this.setDistanceAction(payload)
        .then((response) => {
          if (response.status === 200) {
            //.Step Harita gösterimi
            //this.$router.push({ name: 'Booking.Calculation' })
            this.setFromDateAction(this.fromDate)
            this.setFromTimeAction(this.time)

            this.incrementStepCount()
            //this.IsTheCalculation = true
          } else {
            this.loading = false
            Swal.fire({
              title: this.$t('errors.an_error_occured'),
              text: this.$t('errors.try_again_later'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          }
        })
        .catch(() => {
          this.loading = false
          Swal.fire({
            title: this.$t('errors.an_error_occured'),
            text: this.$t('errors.try_again_later'),
            icon: 'error',
            confirmButtonText: this.$t('close')
          })
        })
    }
  }
}
</script>

<style lang="scss">
.pac-target-input-date {
  width: 100%;
  input {
    border: 1px solid rgb(var(--border-color));
    color: rgb(var(--text-color));
    border-radius: 4px;
    font-size: 1rem;
    padding: 15px 10px;
    width: 100%;
    min-height: 49px;
  }
}
.controls {
  justify-content: end;
}
</style>
