<template>
  <form @submit.prevent="myAccountVerify" class="register w-100">
    <div class="form-item">
      <div class="d-flex flex-row justify-content-between">
        <TheLabel>{{ $t('verification_code') }}</TheLabel>
        <a
          v-if="!isUserInformationForm"
          href="/login"
          class="change-phone-number"
          ><i class="ri-arrow-left-line"></i>
          {{ $t('change_phone_number') }}
        </a>
        <a
          v-if="isUserInformationForm"
          v-on:click="prewVerify()"
          class="change-phone-number"
          ><i class="ri-arrow-left-line"></i> {{ $t('change_phone_number') }}</a
        >
      </div>
      <input
        class="form-control"
        :placeholder="$t('code')"
        v-model.trim="verificationCode"
      />
    </div>
    <div
      class="destination d-flex justify-content-between align-items-center form-btn-area"
    >
      <div class="d-flex justify-content-between w-100 mt-3">
        <TheButton size="btn-sm" :loading="loading">{{
          $t('verify')
        }}</TheButton>
        <div class="d-flex flex-column justify-content-end align-items-end">
          <TheTimeCountDown
            minutes="1"
            seconds="30"
            ref="logoutTimer"
            @onTimerReset="didntGetTheCode"
            v-on:logout="notAuthenticated = true"
            inline-template
          >
            <span
              class="time-count-down"
              :class="timeLeft === '0:00' ? 'didnt-get-the-code' : ''"
              v-on:click="resetTimer()"
            >
              {{
                timeLeft === '0:00' ? "Didn't get the code?" : timeLeft + 'sn.'
              }}
            </span>
          </TheTimeCountDown>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import TheButton from '@/components/TheButton'
import TheLabel from '@/components/TheLabel'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import TheTimeCountDown from '@/components/TheTimeCountDown'

export default {
  name: 'TheMyAccountVerifyCode',

  props: {
    phone_number: {
      type: String
    },
    isChooseYourVehicle: {
      type: Boolean
    },
    setToken: {
      type: Boolean
    },
    formData: {
      type: []
    },
    myAccountSelectedCarPriceId: {
      type: Number
    },
    isUserInformationForm: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      required: false
    }
  },
  mounted() {
    if (this.code) {
      console.log('mounted')
      this.verificationCode = this.code
    }
  },
  components: {
    TheButton,
    TheLabel,
    TheTimeCountDown
  },
  data() {
    return {
      loading: false,
      verificationCode: '',
      myAccountVerifyCode: true,
      registerResponsePhone: '',
      userId: null,
      otp: null,
      country_code: ''
    }
  },
  computed: {
    ...mapGetters({
      getRegister: 'auth/getRegister',
      getGenerate: 'auth/getGenerate'
    })
  },
  methods: {
    ...mapActions({
      registerValidate: 'auth/registerValidate',
      registerValidate2: 'auth/registerValidate2',
      submitBookingAction: 'booking/submitBooking',
      register2: 'auth/register2',
      register: 'auth/register',
      setIndexAction: 'bookingStep/setIndex',
      generate: 'auth/generate'
    }),
    prewVerify() {
      this.$emit('prewVerify')
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    didntGetTheCode() {
      if (this.isUserInformationForm) {
        var data = {
          name: this.getGenerate.name,
          surname: this.getGenerate.surname,
          email: this.getGenerate.email,
          password: 'password',
          phone: this.formatPhoneNumber(
            this.getGenerate.phone,
            `+${this.getGenerate.country_code}`
          ),
          country_code: `+${this.getGenerate.country_code}`
        }
        this.register2(data)
          .then(async () => {})
          .catch(() => {
            Swal.fire({
              text: this.$t('errors.login_failed_description'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.register(this.getRegister)
          .then(async () => {})
          .catch(() => {
            Swal.fire({
              text: this.$t('errors.login_failed_description'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          })
          .finally(() => {
            this.loading = false
          })
      }

      return
    },
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    async myAccountVerify() {
      this.loading = true
      this.submitStatus = 'PENDING'
      let data = null
      if (this.isUserInformationForm) {
        data = {
          phone: this.getGenerate.phone,
          otp: this.verificationCode,
          country_code: this.getGenerate.country_code
        }
      } else {
        data = {
          phone: this.getRegister?.phone,
          otp: this.verificationCode,
          country_code: this.getRegister?.country_code
        }
      }

      if (this.setToken) {
        this.registerValidate2(data)
          .then(async () => {
            //Booking Gönder
            const dataBooking = {}

            const booking = this.$store.state.booking
            dataBooking.km = Math.ceil(booking.distance / 1000)
            dataBooking.duration = Math.ceil(booking.duration / 60)
            dataBooking.from = booking.origin.postal_code
            dataBooking.to = booking.destination.postal_code
            dataBooking.delivery_type = booking.delivery_type_id
            dataBooking.pickup_date = booking.pickup_date
            dataBooking.delivery_date = booking.delivery_date
            dataBooking.from_lat = booking.origin.lat
            dataBooking.from_lng = booking.origin.lng
            dataBooking.to_lat = booking.destination.lat
            dataBooking.to_lng = booking.destination.lng
            dataBooking.to_address = booking.destination.address
            dataBooking.to_name = booking.destination.name
            dataBooking.from_address = booking.origin.address
            dataBooking.from_name = booking.origin.name

            if (this.myAccountSelectedCarPriceId) {
              dataBooking.price_id = Number(this.myAccountSelectedCarPriceId)
            } else {
              dataBooking.price_id = -1
            }

            const list = []
            dataBooking['list'] = list
            this.submitBookingAction(dataBooking)
              .then((bookingId) => {
                if (this.myAccountSelectedCarPriceId) {
                  this.setStep(4)
                } else {
                  this.$router.push({
                    path: `/reservations/${bookingId}`
                  })
                }

                this.submitStatus = 'OK'
              })
              .catch(() => {
                this.loading = false
              })
          })
          .catch(() => {
            Swal.fire({
              text: this.$t('errors.login_failed_description'),
              icon: 'error',
              confirmButtonText: this.$t('close')
            })
          })
          .finally(() => {
            this.loading = false
          })
        return
      }
      this.registerValidate(data)
        .then(async () => {
          //Km bulunamayınca teklif formundan register ol
          if (!this.isChooseYourVehicle) {
            this.$router.replace({
              name: 'Booking'
            })
          }

          const dataBooking = {}

          const booking = this.$store.state.booking
          dataBooking.km = Math.ceil(booking.distance / 1000)
          dataBooking.duration = Math.ceil(booking.duration / 60)
          dataBooking.from = booking.origin.postal_code
          dataBooking.to = booking.destination.postal_code
          dataBooking.delivery_type = booking.delivery_type_id
          dataBooking.pickup_date = booking.pickup_date
          dataBooking.delivery_date = booking.delivery_date
          dataBooking.from_lat = booking.origin.lat
          dataBooking.from_lng = booking.origin.lng
          dataBooking.to_lat = booking.destination.lat
          dataBooking.to_lng = booking.destination.lng
          dataBooking.to_address = booking.destination.address
          dataBooking.to_name = booking.destination.name
          dataBooking.from_address = booking.origin.address
          dataBooking.from_name = booking.origin.name

          if (this.myAccountSelectedCarPriceId) {
            dataBooking.price_id = Number(this.myAccountSelectedCarPriceId)
          } else {
            dataBooking.price_id = -1
          }

          const list = []
          dataBooking['list'] = list
          this.submitBookingAction(dataBooking)
            .then((bookingId) => {
              if (this.myAccountSelectedCarPriceId) {
                this.setStep(4)
              } else {
                this.$router.push({
                  path: `/reservations/${bookingId}`
                })
              }

              this.submitStatus = 'OK'
              this.loading = false
            })
            .catch(() => {
              // this.setStep(4)

              this.loading = false
            })

          this.verification_code = false
          // this.loading = false
        })
        .catch(() => {
          // Swal.fire({
          //   title: this.$t('errors.login_failed'),
          //   text: this.$t('errors.login_failed_description'),
          //   icon: 'error',
          //   confirmButtonText: this.$t('close')
          // })
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
