import axios from 'axios'
import FormData from 'form-data'

export default {
  namespaced: true,
  state: {
    origin: null,
    destination: null,
    distance: null,
    duration: null,
    polyline_points: null,
    packages: null,
    delivery_type: null,
    delivery_type_id: null,
    delivery_date: null,
    pickup_date: null,
    tracking_code: null,
    box_total: null,
    lastpall_total: null,
    annat_format_total: null,
    user_type: null,
    fromDate: null,
    selectedCar: null,
    bookings: null,
    carsList: null
  },
  getters: {
    getPackage: (state) => (type) => {
      if (type === 'box') {
        return state.box_total
      } else if (type === 'lastpall') {
        return state.lastpall_total
      } else if (type === 'annat_format') {
        return state.annat_format_total
      }
    },
    getCarsList(state) {
      return state.carsList
    },
    getFromDate(state) {
      return state.fromDate
    },
    getFromTime(state) {
      return state.fromTime
    },
    getFrom(state) {
      return state.from
    },
    getTo(state) {
      return state.to
    },
    getSelectedCar(state) {
      return state.selectedCar
    },
    getBookingId(state) {
      return state.bookings.id
    },
    getBooking(state) {
      return state.bookings
    },
    getBookingPrice(state) {
      return state.bookings.data
    }
  },
  mutations: {
    SET_ROUTE(state, payload) {
      state.origin = payload.origin
      state.destination = payload.destination
    },
    SET_USER_TYPE(state, payload) {
      state.user_type = payload.user_type
    },
    SET_PACKAGES(state, payload) {
      state.packages = payload
    },
    SET_SELECTED_CAR(state, payload) {
      state.selectedCar = payload
    },
    CLEAR_STATE(state) {
      state.origin = null
      state.destination = null
      state.distance = null
      state.duration = null
      state.polyline_points = null
      state.packages = null
      state.delivery_type = null
      state.delivery_type_id = null
      state.delivery_date = null
      state.pickup_date = null
      state.tracking_code = null
      state.user_type = null
      state.box_total = null
      state.lastpall_total = null
      state.annat_format_total = null
      state.fromDate = null
      state.fromTime = null
    },
    SET_DELIVERY_TYPE(state, payload) {
      state.delivery_type = payload.delivery_type
      state.delivery_type_id = payload.delivery_type_id
      state.delivery_date = payload.delivery_date
      state.pickup_date = payload.pickup_date
    },
    SET_CAR(state, payload) {
      state.carsList = payload.calculation_result
    },
    SET_DISTANCE(state, payload) {
      state.distance = payload.distance
      state.duration = payload.duration
      state.polyline_points = payload.polyline_points
    },

    SET_TRACKING_CODE(state, payload) {
      state.tracking_code = payload
    },
    SET_FROM_DATE(state, payload) {
      state.fromDate = payload
    },
    SET_FROM_TIME(state, payload) {
      state.fromTime = payload
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload
    }
  },
  actions: {
    clearState({ commit }) {
      commit('CLEAR_STATE')
    },
    setRoute({ commit }, payload) {
      commit('SET_ROUTE', payload)
    },
    setUserType({ commit }, payload) {
      commit('SET_USER_TYPE', payload)
    },
    setPackages({ commit }, payload) {
      commit('SET_PACKAGES', payload)
    },
    setDeliveryType({ commit }, payload) {
      commit('SET_DELIVERY_TYPE', payload)
    },
    submitBooking({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = new FormData()
        data.append('other_user_id', payload.other_user_id)
        data.append('price_id', payload.price_id)
        data.append('km', payload.km)
        data.append('duration', payload.duration)
        data.append('from', payload.from)
        data.append('from_name', payload.from_name)
        data.append('from_address', payload.from_address)
        data.append('from_lat', payload.from_lat)
        data.append('from_lng', payload.from_lng)
        data.append('to', payload.to)
        data.append('to_name', payload.to_name)
        data.append('to_address', payload.to_address)
        data.append('to_lat', payload.to_lat)
        data.append('to_lng', payload.to_lng)
        data.append('booking_date', payload.booking_date)
        data.append('booking_time', payload.booking_time)
        axios
          .post('api/bookings', data)
          .then(function (response) {
            commit('SET_BOOKINGS', response.data)
            commit('SET_TRACKING_CODE', response.data.track_code)
            resolve(response.data.id)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    async calculatePrice({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          km: payload.km
        }
        const config = {
          method: 'post',
          url: 'api/pricecalculate',
          data: data
        }
        axios(config)
          .then(function (response) {
            if (!response.data.errors) {
              response.data.forEach((item) => {
                if (item) {
                  commit('SET_CAR', {
                    calculation_result: response.data
                  })
                }
              })
            }

            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    setDistance({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const origin = payload.origin.name
        const destination = payload.destination.name
        axios
          .get(`api/direction?origin=${origin}&destination=${destination}`)
          .then((response) => {
            const payload = {
              distance: response.data.distance,
              duration: response.data.duration,
              polyline_points: response.data.polyline
            }
            commit('SET_DISTANCE', payload)
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    setFromDate({ commit }, payload) {
      commit('SET_FROM_DATE', payload)
    },
    setFromTime({ commit }, payload) {
      commit('SET_FROM_TIME', payload)
    },
    setSelectedCar({ commit }, payload) {
      commit('SET_SELECTED_CAR', payload)
    }
  }
}
