<template>
  <div class="px-lg-4 px-md-4 px-0">
    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="''"
      v-bind:description="this.contracts"
      @close="logChange"
    />
    <div class="row">
      <div class="col-12 d-flex juistify-content-start mb-3">
        <div class="form-check mr-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="full_payment"
            v-model="fullPayment"
            @change="onPaymentTypeChange(true)"
          />
          <label class="form-check-label d-inline-block" for="full_payment">
            {{ $t('full_payment') }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="advance_payment"
            v-model="advancePayment"
            @change="onPaymentTypeChange(false)"
          />
          <label class="form-check-label d-inline-block" for="advance_payment">
            {{ $t('advance_payment_title') }}
          </label>
        </div>
      </div>
    </div>
    <div
      class="alert alert-warning text-left d-flex flex-column align-items-start advance-payment"
      role="alert"
      v-if="!fullPayment"
    >
      <b class="text text-color-dark">{{ $t('advance_payment_title') }}</b>
      {{ $t('pre_payment_text') }}
    </div>

    <div
      class="alert alert-info text-left d-flex flex-column align-items-start payment-alert-info full-payment-alert-info"
      role="alert"
      v-if="fullPayment"
    >
      <b class="text text-color-success"
        >{{ this.bookingPrice.full_discount }}% {{ $t('discount') }}</b
      >

      <div v-if="this.selectedCar.free_cancellation > 0">
        {{
          $t('discount_text_with_cancellation', [
            this.bookingPrice.full_discount,
            this.selectedCar.free_cancellation
          ])
        }}
      </div>
      <div v-if="this.selectedCar.free_cancellation == 0">
        {{ $t('discount_text', [this.bookingPrice.full_discount]) }}
      </div>
    </div>

    <div class="payment-total-area mt-5">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <strong class="text-left"
            >{{ $t('amountt_o_be_withdrawn_from_your_credit_card') }} :</strong
          >

          <p v-if="fullPayment" class="badge badge-color-success ml-2">
            {{ this.bookingPrice.full_discount }}% {{ $t('discount') }}
          </p>
        </div>

        <abbr
          v-if="fullPayment"
          class="d-flex flex-column align-items-end pierce-area"
        >
          <small v-if="isDiscounted"
            >CHF {{ this.bookingPrice.discount_price }}</small
          >
          <span>CHF {{ this.bookingPrice.full_discount_price }}</span></abbr
        >

        <abbr
          v-if="!fullPayment"
          class="d-flex flex-column align-items-end pierce-area"
        >
          <span>CHF {{ this.bookingPrice.system_payment }}</span></abbr
        >
      </div>
    </div>
    <div class="register-container">
      <form @submit.prevent="submit" class="register">
        <div class="form-row">
          <div class="col-md-12">
            <div v-if="!showPayment">
              <div
                v-for="(item, index) in privacyArray"
                :key="index"
                class="privacy-item"
              >
                <div
                  class="form-item form-check mr-3 mb-5 mt-5 align-items align-items-start"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="privacy"
                    v-model="item.selected"
                  />
                  <span class="form-check-label d-inline-block" for="privacy">
                    <a
                      href="javascript:;"
                      :id="item.id"
                      v-on:click="privacyDetail(item.id, $event)"
                      >{{ item.prefix }} {{ item.name }} {{ item.suffix }}.</a
                    >
                  </span>
                  <span
                    class="error text-danger"
                    v-if="
                      item.selected !== undefined &&
                      !$v.privacyArray.$each[index].selected.required &&
                      $v.privacyArray.$each[index].selected.$dirty
                    "
                  >
                    {{ $t('value_is_required') }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="showPayment">
              <iframe
                v-if="iframe.loaded"
                :src="iframe.src"
                :style="iframe.style"
                @iframe-load="onLoad"
                width="500px"
                height="500px"
                scrolling="no"
                frameborder="0"
              />
            </div>

            <div class="d-flex justify-content-between">
              <TheButton
                @click="
                  showPayment == true
                    ? (showPayment = false)
                    : deccrementStepCount()
                "
                reverse="reverse"
                size="btn-sm"
                type="button"
                :disabled="false"
                >{{ $t('back') }}
              </TheButton>

              <TheButton
                :disabled="disabledPayment"
                v-if="!showPayment"
                customClass="ml-3"
                size="btn-sm"
                >{{ $t('pay_now') }}
              </TheButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TheModal from '@/components/TheModal'
import TheButton from '@/components/TheButton'
import axios from 'axios'
export default {
  name: 'Register',
  components: {
    TheModal,
    TheButton
  },

  data() {
    return {
      disabledPayment: true,
      showPayment: false,
      showModal: false,
      myIframe: null,
      contracts: null,
      position: null,
      prefix: null,
      required: null,
      selected: null,
      suffix: null,
      privacy: null,
      privacyForm: false,
      privacyArray: [],
      iframe: {
        loaded: false,
        src: '',
        style: null
      },
      name_sur_name: '',
      card_number: '',
      expired_month: '',
      expired_year: '',
      expired_ccv: '',
      advancePayment: false,
      fullPayment: true,

      loading: false
    }
  },
  mounted() {
    this.setIframeSrc()
    this.iframe.loaded = true

    var lang = 'en'
    if (localStorage.getItem('lang')) {
      lang = JSON.parse(localStorage.getItem('lang'))
    }

    axios
      .get('api/contracts/active?lang=' + lang)
      .then((response) => (this.contracts = response.data))
      .catch((error) => {
        this.errorMessage = error.message
        console.error('There was an error!', error)
      })

    axios
      .get('api/contracts/active?lang=' + lang)
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          for (const item of response.data) {
            if (item.position === 'payment') {
              this.privacyArray.push(item)
            }
          }
        }
        this.disabledPayment = false
      })
      .catch((error) => {
        this.errorMessage = error.message
        console.error('There was an error!', error)
      })
  },

  validations: {
    privacyArray: {
      $each: {
        selected: {
          required: function (val, item) {
            if (item.required) {
              return val
            }
            return true
          }
        }
      }
    }
  },

  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),

    onLoad(frame) {
      this.myIframe = frame.contentWindow
    },
    logChange(event) {
      this.showModal = event
    },
    setIframeSrc() {
      var baseUrl = process.env.VUE_APP_API_BASE_URL
      this.iframe.src = `${baseUrl}api/payment?bookingId=${
        this.bookingId
      }&paymentType=${this.fullPayment ? 'Full' : 'Pre'}`
    },

    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.showPayment = true
      }
    },

    privacyDetail(id, event) {
      console.log(id)
      console.log(event)
      this.showModal = true
      this.contracts = 'Loading...'

      // var baseUrl = process.env.VUE_APP_API_BASE_URL
      // this.iframe.src = `${baseUrl}api/payment?bookingId=${
      //   this.bookingId
      // }&paymentType=${this.fullPayment ? 'Full' : 'Pre'}`

      axios
        .get(
          `api/contracts/active/${id}&payment?bookingId=${
            this.bookingId
          }&paymentType=${this.fullPayment ? 'Full' : 'Pre'}`
        )
        .then((response) => (this.contracts = response.data))
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      var incrementStep = this.step
      incrementStep--
      this.setIndexAction(incrementStep)
    },
    ...mapActions({
      setIndexAction: 'bookingStep/setIndex'
    }),

    onPaymentTypeChange: function (type) {
      if (type) {
        this.fullPayment = true
        this.advancePayment = false
      } else {
        this.fullPayment = false
        this.advancePayment = true
      }

      this.setIframeSrc()
    }
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      bookingId: 'booking/getBookingId',
      bookingPrice: 'booking/getBookingPrice',
      selectedCar: 'booking/getSelectedCar'
    }),
    isDiscounted: function () {
      return (
        this.bookingPrice.discount_price !==
        this.bookingPrice.full_discount_price
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.advance-payment {
  background: rgba(248, 241, 227, 1);
  font-size: 13px;
  color: rgb(var(--text-color));
  b {
    margin-bottom: 8px;
  }
  .text-color-dark {
    font-size: 18px;
    color: rgb(var(--text-color));
  }
}
.pierce-area {
  small {
    text-decoration: line-through;
    color: rgb(var(--tertiary-color));
    font-size: 12px;
  }

  span {
    font-size: 18px;
    color: rgb(var(--secondary-color));
    font-weight: 600;
  }
}
.badge-color-success {
  background-color: rgb(var(--color-success));
  color: rgb(var(--white-color));
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 15px;
}
.payment-alert-info {
  font-size: 13px;
  color: rgb(var(--text-color));
  b {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .text-color-success {
    color: rgb(var(--color-success));
  }
}

.form-check {
  label {
    color: rgb(var(--primary-color));
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
}

.payment-total-area {
  strong {
    max-width: 306px;
  }
}

.form-item {
  display: flex;
  flex-direction: column;
}
</style>
