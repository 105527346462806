<template>
  <div>
    <div v-if="this.isResponseNotKmForm === true">
      <div v-if="items.length == 0">Loading...</div>

      <div v-if="items.length > 0">
        <VueSlickCarousel v-bind="settings">
          <TheChooseYourVehicleItem
            :baggage="item.baggage"
            :discount_price="item.discount_price"
            :name="item.name"
            :image="item.image"
            :person="item.person"
            :free_cancellation="item.free_cancellation"
            :price="item.price"
            :price_id="item.price_id"
            v-for="item in items"
            :key="item.price_id"
          />
        </VueSlickCarousel>

        <TheButton reverse="reverse" size="btn-sm" @click="deccrementStepCount"
          >{{ $t('back') }}
        </TheButton>
      </div>
    </div>
    <div v-else class="register-form">
      <TheResponseNotKmForm />
    </div>
  </div>
</template>
<script>
// import TheLabel from '@/components/TheLabel'
import { mapGetters, mapActions } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import TheChooseYourVehicleItem from '@/components/TheChooseYourVehicleItem'
import TheResponseNotKmForm from '@/components/TheResponseNotKmForm'
import Swal from 'sweetalert2'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TheButton from '@/components/TheButton'
export default {
  data() {
    return {
      isResponseNotKmForm: true,
      settings: {
        arrows: false,
        dots: false,
        autoplay: false,
        slidesPerRow: 3,
        swipe: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: false,
              autoplay: false,
              slidesPerRow: 3,
              swipe: true,
              centerMode: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesPerRow: 1,
              infinite: true,
              centerMode: true
            }
          }
        ]
      },
      items: []
    }
  },

  components: {
    VueSlickCarousel,
    TheChooseYourVehicleItem,
    TheResponseNotKmForm,
    TheButton
  },
  computed: {
    ...mapGetters({
      step: 'bookingStep/getIndex',
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      cars: 'booking/getCarsList'
    })
  },
  async mounted() {
    const booking = this.$store.state.booking

    this.polyline_points = booking.polyline_points
    this.origin = booking.origin
    this.destination = booking.destination
    this.distance = booking.distance
    this.duration = booking.duration

    if (this.cars) {
      this.items = this.cars
      this.isResponseNotKmForm = true
      return
    }

    const data = {
      km: Math.ceil(this.distance / 1000)
    }

    const response = await this.calculatePriceAction(data)
    if (response.status === 200) {
      this.isResponseNotKmForm = true
      if (response.data.errors) {
        return Swal.fire({
          title: `${this.$t('custom_special_offer_text')}`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `${this.$t('yes')}`,
          denyButtonText: `${this.$t('no')}`
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.isResponseNotKmForm = false
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            this.setIndexAction(1)
            //this.$router.push({ name: 'Booking' })
          }
        })
      }

      this.items = response.data
    }

    this.loading = false
  },
  methods: {
    incrementStepCount() {
      var incrementStep = this.step
      incrementStep++
      this.setIndexAction(incrementStep)
    },
    deccrementStepCount() {
      var incrementStep = this.step
      incrementStep--
      this.setIndexAction(incrementStep)
    },
    ...mapActions({
      setIndexAction: 'bookingStep/setIndex',
      calculatePriceAction: 'booking/calculatePrice'
    })
  }
}
</script>

<style>
.cauresel-area > div > div > div > div > div {
  display: flex;
}
</style>
