<template>
  <div class="app">
    <div class="navigation container">
      <Navigation />
    </div>
    <div class="content container">
      <router-view />
    </div>
    <div class="footer container">
      <a
        v-if="this.page_copyright_link"
        :href="this.page_copyright_link.value"
        class="copyright"
        >{{ this.$t('brand') }} &copy; {{ year }}</a
      >
      <div class="links">
        <a
          v-if="this.page_help_link"
          target="_blank"
          :href="this.page_help_link.value"
          >{{ $t('policies') }}</a
        >
        <a
          v-if="this.page_contact_link"
          target="_blank"
          :href="this.page_contact_link.value"
          >{{ $t('contact') }}</a
        >
        <a
          v-if="this.page_facebook_link"
          target="_blank"
          :href="this.page_facebook_link.value"
          ><Facebook
        /></a>
        <a
          v-if="this.page_twitter_link"
          target="_blank"
          :href="this.page_twitter_link.value"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M22.2125 5.65605C21.4491 5.99375 20.6395 6.21555 19.8106 6.31411C20.6839 5.79132 21.3374 4.9689 21.6493 4.00005C20.8287 4.48761 19.9305 4.83077 18.9938 5.01461C18.2031 4.17106 17.098 3.69303 15.9418 3.69434C13.6326 3.69434 11.7597 5.56661 11.7597 7.87683C11.7597 8.20458 11.7973 8.52242 11.8676 8.82909C8.39047 8.65404 5.31007 6.99005 3.24678 4.45941C2.87529 5.09767 2.68005 5.82318 2.68104 6.56167C2.68104 8.01259 3.4196 9.29324 4.54149 10.043C3.87737 10.022 3.22788 9.84264 2.64718 9.51973C2.64654 9.5373 2.64654 9.55487 2.64654 9.57148C2.64654 11.5984 4.08819 13.2892 6.00199 13.6731C5.6428 13.7703 5.27232 13.8194 4.90022 13.8191C4.62997 13.8191 4.36771 13.7942 4.11279 13.7453C4.64531 15.4065 6.18886 16.6159 8.0196 16.6491C6.53813 17.8118 4.70869 18.4426 2.82543 18.4399C2.49212 18.4402 2.15909 18.4205 1.82812 18.3811C3.74004 19.6102 5.96552 20.2625 8.23842 20.2601C15.9316 20.2601 20.138 13.8875 20.138 8.36111C20.138 8.1803 20.1336 7.99886 20.1256 7.81997C20.9443 7.22845 21.651 6.49567 22.2125 5.65605Z"
            ></path>
          </svg>
        </a>
        <a v-if="this.page_email" :href="'mailto:' + this.page_email.value">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM20 7.23792L12.0718 14.338L4 7.21594V19H20V7.23792ZM4.51146 5L12.0619 11.662L19.501 5H4.51146Z"
            ></path>
          </svg>
        </a>

        <a
          v-if="this.page_instagram_link"
          target="_blank"
          :href="this.page_instagram_link.value"
          ><Instagram
        /></a>
        <a
          v-if="this.page_linkedin_link"
          target="_blank"
          :href="this.page_linkedin_link.value"
          ><Linkedin
        /></a>

        <a v-if="page_payment_link" :href="page_payment_link.value">
          <img
            v-if="page_payment_logo"
            class="ml-3"
            :src="this.page_payment_logo.value"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import Facebook from '@/assets/icons/social/facebook.svg'
import Instagram from '@/assets/icons/social/instagram.svg'
import Linkedin from '@/assets/icons/social/linkedin.svg'
// import Container from '@/components/TheContainer.vue'
import Navigation from '@/components/TheNavigation.vue'

export default {
  name: 'App',
  beforeCreate() {},
  metaInfo: {
    title: process.env.VUE_APP_TITLE
  },
  components: {
    // Container,
    Navigation,
    Facebook,
    Linkedin,
    Instagram
  },
  created() {
    this.$store.dispatch('homeSettings/setSettings').then(() => {
      this.page_copyright_link = this.$store.getters[
        'homeSettings/getSettingByCode'
      ]('page_copyright_link')

      this.page_help_link =
        this.$store.getters['homeSettings/getSettingByCode']('page_help_link')

      this.page_contact_link =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_contact_link'
        )

      this.page_facebook_link =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_facebook_link'
        )
      this.page_twitter_link =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_twitter_link'
        )

      this.page_instagram_link = this.$store.getters[
        'homeSettings/getSettingByCode'
      ]('page_instagram_link')

      this.page_linkedin_link =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_linkedin_link'
        )
      this.page_payment_logo =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_payment_logo'
        )
      this.page_payment_link =
        this.$store.getters['homeSettings/getSettingByCode'](
          'page_payment_link'
        )

      this.page_email =
        this.$store.getters['homeSettings/getSettingByCode']('page_email')
    })
  },
  data() {
    return {
      page_copyright_link: null,
      page_help_link: null,
      page_contact_link: null,
      page_facebook_link: null,
      page_twitter_link: null,
      page_linkedin_link: null,
      page_instagram_link: null,
      page_payment_logo: null,
      page_payment_link: null,
      page_email: null,
      year: new Date().getFullYear(),
      theme: process.env.VUE_APP_THEME
    }
  },
  mounted() {
    let faviconSetting =
      this.$store.getters['homeSettings/getSettingByCode']('page_favicon')
    if (faviconSetting) {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = faviconSetting.value
      document.getElementsByTagName('head')[0].appendChild(link)
    } else {
      console.log('Favicon setting not found')
    }
  },
  methods: {
    navigateToHome() {
      this.$router.push({ name: 'Booking.Index' })
    },
    navigateToBack() {
      this.$router.back()
    },
    getNavigateToHomeButtonStatus() {
      const blocked_names = ['Booking.Index', 'Tracking', 'Login']
      return blocked_names.some((e) => e === this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css';
@import './styles/grid.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.header {
  margin-top: 30px;
  margin-bottom: 40px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.navigation {
  margin-bottom: 96px;
  padding: 0;
}

.navigate-home {
  cursor: pointer;
  color: rgb(var(--primary-color));
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}

.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  flex: 1;
}
.footer {
  margin-top: 76px;
  margin-bottom: 76px;
  color: rgb(var(--primary-color));
  display: flex;
  justify-content: space-between;

  .copyright {
    display: flex;
    align-items: center;
    color: #000;
  }
  .links {
    display: flex;
    align-items: center;

    a {
      margin-left: 20px;
      color: rgb(var(--primary-color));
      display: flex;
      align-items: center;
    }

    a:first-child {
      border-right: 2px solid rgb(var(--primary-color));
      padding-right: 20px;
    }
    a:nth-child(2) {
      border-right: 2px solid rgb(var(--primary-color));
      padding-right: 20px;
    }

    svg {
      width: 18px;
    }
  }
}

.main-logo {
  cursor: pointer;
}
</style>
