<template>
  <div class="row">
    <div class="col-lg-6 col-md-12 register-left-text-area">
      <h4>
        {{ $t('we_will_get_back_to_you_as_soon_as_possible') }}
      </h4>
      <span>
        {{ $t('special_offer_text') }}
      </span>
    </div>
    <div class="col-lg-6 col-md-12" v-if="registerForm">
      <!-- <h4>{{ $t('reservation_form') }}</h4> -->
      <div class="register-container">
        <form @submit.prevent="submit" class="register w-100">
          <div
            class="form-group form-item"
            :class="{ 'form-group--error': $v.form.name.$error }"
          >
            <TheLabel
              >{{ $t('name') }}
              <span class="error text-danger" v-if="!$v.form.name.required">
                *
              </span>
            </TheLabel>
            <input
              class="form-control"
              :placeholder="$t('name')"
              v-model.trim="$v.form.name.$model"
            />
            <span
              class="error text-danger"
              v-if="!$v.form.name.required && $v.form.name.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>

          <div
            class="form-group form-item"
            :class="{ 'form-group--error': $v.form.surname.$error }"
          >
            <TheLabel
              >{{ $t('surname') }}
              <span class="error text-danger" v-if="!$v.form.surname.required">
                *
              </span>
            </TheLabel>
            <input
              :placeholder="$t('surname')"
              class="form-control"
              v-model.trim="$v.form.surname.$model"
            />
            <span
              class="error text-danger"
              v-if="!$v.form.surname.required && $v.form.surname.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>

          <div
            class="form-item form-group"
            :class="{ 'form-group--error': $v.form.e_mail.$error }"
          >
            <TheLabel
              >{{ $t('email') }}
              <span
                class="error text-danger"
                v-if="!$v.form.e_mail.required && $v.form.e_mail.$dirty"
              >
                *
              </span>
            </TheLabel>
            <input
              :placeholder="$t('email')"
              class="form-control"
              v-model.trim="$v.form.e_mail.$model"
            />

            <span
              class="error text-danger"
              v-if="!$v.form.e_mail.required && $v.form.e_mail.$dirty"
            >
              {{ $t('value_is_required') }}
            </span>
          </div>

          <div class="form-item form-group">
            <vue-tel-input
              v-bind="this.bindProps"
              v-model="form.phone_number"
              class="tel-input"
              :placeholder="$t('phone')"
              v-on:country-changed="countryChanged"
              :defaultCountry="defaultCountry"
              :key="componentKey"
              @validate="validatePhone"
            ></vue-tel-input>
            <span class="error text-danger" v-if="phoneIsValid === false">
              {{ $t('phone_is_not_valid') }}
            </span>
          </div>
          <div
            class="destination d-flex justify-content-between align-items-center form-btn-area"
          >
            <TheButton size="btn-sm" :loading="loading"
              >{{ $t('get_an_offer') }}
            </TheButton>
          </div>
        </form>
      </div>
    </div>
    <div
      class="verification_code col-lg-6 col-md-12"
      v-if="myAccountVerifyCode"
    >
      <TheMyAccountVerifyCode
        v-bind:setToken="setToken"
        v-bind:phone_number="form.phone_number"
        v-bind:isChooseYourVehicle="isRegisterChooseYourVehicle"
        v-bind:isUserInformationForm="true"
        @prewVerify="verifyReset"
        :code="this.code"
      ></TheMyAccountVerifyCode>
    </div>
    <div class="verification_code col-lg-6 col-md-12" v-if="verification_code">
      <TheLoginVerifyCode
        v-bind:setToken="setToken"
        v-bind:formData="form"
        v-bind:isChooseYourVehicle="isLoginChooseYourVehicle"
        v-bind:userIdd="userId"
        v-bind:isUserInformationForm="true"
        @prewVerify="verifyReset"
        :code="this.code"
      ></TheLoginVerifyCode>
    </div>
  </div>
</template>

<script lang="js">
import TheButton from "@/components/TheButton";
import { mapActions, mapGetters } from "vuex";
import TheLabel from "@/components/TheLabel";
import TheMyAccountVerifyCode from "@/components/TheMyAccountVerifyCode";
import TheLoginVerifyCode from "@/components/TheLoginVerifyCode";
import Swal from 'sweetalert2'
import { required } from "vuelidate/lib/validators";
import countries from '../assets/telInputcountryData.js';
import moment from 'moment'

// import Swal from 'sweetalert2'
 import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
export default {
  name: "TheResponseNotKmForm",
  components: {
    TheButton,
    TheLabel,
    TheMyAccountVerifyCode,
    TheLoginVerifyCode,
     VueTelInput
  },
  mounted() {
    const user = this.$store.state.auth.user
    if (user) {
      this.form.e_mail = user.email
      this.form.name = user.name
      this.form.surname = user.surname
      this.form.phone_number = user.country_code + user.phone
      this.userId = user.user_id
      this.user_type = user.type
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      fromDate: 'booking/getFromDate'
    }),
  },
  data() {
    return {
      phoneIsValid: undefined,
      countries: countries,
      code:'',
      form: {
        name: "",
        surname: "",
        e_mail: "",
        phone_number: "",
        country_code: "",
      },

      isLoginChooseYourVehicle: false,
      isRegisterChooseYourVehicle: false,
      loading: false,
      verificationCode: "",
      verification_code: false,
      loginVerification_code: false,
      myAccountVerifyCode: false,
      registerForm: true,
      registerResponsePhone: "",
      userId: "",
      otp: null,
      priceId: null,
      bindProps: {
        autofocus: false,
        autoFormat: true,
        inputOptions: {
          showDialCode: true
        }
      },
      defaultCountry: "CH",
      componentKey: 0,  // initial key

    };
  },
  validations: {
    form: {
      name: {
        required
      },
      surname: {
        required
      },
      e_mail: {
        required
      },
    }
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    },
    validatePhone(object) {
      this.phoneIsValid = object.valid;
    },
    countryChanged(country) {
      this.form.country_code = country.dialCode
    },
    formatPhoneNumber(phone, dialCode) {
      let formattedPhone = phone.replace(/\s/g, '')
      formattedPhone = formattedPhone.replace(dialCode, '')
      return formattedPhone
    },
    ...mapActions({
      register: "auth/register",
      submitBookingAction: 'booking/submitBooking',
      generate: 'auth/generate',
      setIndexAction: 'bookingStep/setIndex',
      register2: 'auth/register2'
    }),
    setStep(setIndex) {
      this.setIndexAction(setIndex)
    },
    verifyReset() {
      this.verification_code = false
      this.loginVerification_code = false
      this.myAccountVerifyCode = false
      this.registerForm = true
    },
    submit() {
      this.$v.$touch()

      if (this.phoneIsValid !== true) {
        this.phoneIsValid=false;
      }

      if (this.$v.$invalid || this.phoneIsValid !== true) {
        this.submitStatus = 'ERROR'
        return
      } else {
        this.loading = true

        //giriş yapılıysa booking gönder
        if (this.authenticated) {

          //Numara farklı ise aksiyon al start
          if (this.formatPhoneNumber(
            this.form.phone_number,
            `+${this.form.country_code}`
          ) !== this.$store.state.auth.user.phone) {
            //Phone kontrolü
            this.setToken = true
            this.loading = true
            const data = {
              name: this.form.name,
              surname: this.form.surname,
              email: this.form.e_mail,
              phone: this.formatPhoneNumber(
            this.form.phone_number,
            `+${this.form.country_code}`
          ),
          country_code: `+${this.form.country_code}`
            }

            this.generate(data)
              .then(async (data) => {
                if (data !== null && data.user_id !== null) {
                  this.userId = data.user_id
                  this.code = data.otp
                  this.verification_code = true
                  this.isLoginChooseYourVehicle = true
                  this.registerForm = false
                }
              })
              .catch(async (err) => {
                if (err.response) {
                  if (err.response.status == 400) {
                    const data = {
                      name: this.form.name,
                      surname: this.form.surname,
                      email: this.form.e_mail,
                      phone: this.formatPhoneNumber(
                        this.form.phone_number,
                        `+${this.form.country_code}`
                      ),
                      country_code: `+${this.form.country_code}`,
                      password: 'password'
                    }
                    console.log('register2');
                    let registerResponse = await this.register2(
                      JSON.parse(JSON.stringify(data))
                    )
                    if (registerResponse.status == 200) {

                      if (registerResponse != null && registerResponse.otp) {
                        this.code = registerResponse.otp.toString()
                      }

                      if (registerResponse.user_id) {
                        this.userId = registerResponse.user_id
                        this.verification_code = false
                        this.loginVerification_code = true
                        this.registerForm = false
                      } else {
                        this.registerResponsePhone = registerResponse.phone
                        this.myAccountVerifyCode = true
                        this.isRegisterChooseYourVehicle = true
                        this.registerForm = false
                      }
                    }
                    else if(registerResponse.status == 400){
                      var error = registerResponse.data.error.map(function (q) {return `${q} <br/>`});
                    await Swal.fire({
                        title: this.$t('errors.login_failed'),
                        html: error,
                        icon: 'error',
                        confirmButtonText: this.$t('close')
                      })
                    }
                    return
                  }
                  // ✅ log status code here
                  console.log(err.response.status)
                  console.log(err.message)
                  console.log(err.response.headers)
                  console.log(err.response.data)
                }

                this.verification_code = false
              })
              .finally(() => {
                this.loading = false
              })
            return
          }
          //Numara farklı ise aksiiyon al end


          this.submitStatus = 'PENDING'
          const data = {}

          const booking = this.$store.state.booking
          data.km = Math.ceil(booking.distance / 1000)
          data.duration = Math.ceil(booking.duration / 60)
          data.from = booking.origin.postal_code
          data.to = booking.destination.postal_code
          data.delivery_type = booking.delivery_type_id
          data.pickup_date = booking.pickup_date
          data.delivery_date = booking.delivery_date
          data.from_lat = booking.origin.lat
          data.from_lng = booking.origin.lng
          data.to_lat = booking.destination.lat
          data.to_lng = booking.destination.lng
          data.to_address = booking.destination.address
          data.to_name = booking.destination.name
          data.from_address = booking.origin.address
          data.from_name = booking.origin.name
          data.booking_date = this.format_date(booking.fromDate)
          data.booking_time = this.format_time(booking.fromTime)
          data.price_id = -1

          const list = []
          data['list'] = list
          this.submitBookingAction(data)
            .then((bookingId) => {
               this.setStep(1)
              this.$router.push({
                path: `/reservations/${bookingId}`,
              })
              this.submitStatus = 'OK'
              this.loading = false
            })
            .catch(() => {
              this.setStep(1)
              this.$router.push({
                name: 'Booking.Result',
                query: { status: 'error' }
              })
              this.loading = false
            })
          return false
        }

        const data = {
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.e_mail,
          phone: this.formatPhoneNumber(this.form.phone_number, `+${this.form.country_code}`),
              country_code: `+${this.form.country_code}`,
        }


        this.generate(data)
          .then(async (data) => {
            if (data !== null && data.user_id !== null) {
              this.userId = data.user_id
              this.verification_code = true
              this.registerForm = false
              this.isLoginChooseYourVehicle = true

            }
            this.loading = false
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status == 400) {

                const data = {
                  name: this.form.name,
                  surname: this.form.surname,
                  email: this.form.e_mail,
                  phone: this.formatPhoneNumber(this.form.phone_number, `+${this.form.country_code}`),
                  country_code: `+${this.form.country_code}`,
                  password: "password"
                }
                this.register(data)
                  .then((response) => {
                    response = JSON.parse(response)
                    if (response != null && response.otp) {
                        this.code = response.otp.toString()
                      }

                    if (response.user_id) {
                      this.userId = response.user_id
                      this.verification_code = false
                      this.loginVerification_code = true
                    } else {
                      this.registerResponsePhone = response.phone
                      this.myAccountVerifyCode = true
                      this.isRegisterChooseYourVehicle = true
                      this.registerForm = false
                    }

                  })
                  .catch((err) => {
                    console.log(err.response);
                    Swal.fire({
                      title: this.$t('errors.login_failed'),
                      text: err.response.data.error,
                      icon: 'error',
                      confirmButtonText: this.$t('close')
                    })
                    this.loading = false
                  })

              }
            }

            ; (this.loading = false), (this.verification_code = false)
          })
      }
    }



  }

};
</script>

<style lang="scss" scoped>
.register-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .register {
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .form-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      align-items: flex-start;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .form-btn-area {
    a {
      font-size: 16px;
      color: rgb(var(--text-color));
      font-weight: 500;
    }
  }
}

.register-left-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h4 {
    color: var(--primary-color);
    text-align: left;
    font: normal normal 29px/43px Poppins;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 29px;
    margin-bottom: 30px;
  }

  span {
    color: var(--text-color);
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

h4 {
  font-size: 20px;
  color: rgb(var(--text-color));
  font-weight: 600;
}
</style>
