import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Booking from '../views/booking/Index'
import Tracking from '../views/Tracking.vue'
import Login from '../views/Login.vue'
import Contracts from '../views/Contracts.vue'
import ContractDetail from '../views/ContractDetail.vue'
import MyAccount from '../views/MyAccount.vue'
import Profile from '../views/Profile.vue'
import Reservations from '../views/Reservations.vue'
import TrackingResult from '../views/TrackingResult.vue'
import Booking from '../views/booking.vue'
import ReservationsDetail from '../views/ReservationsDetail.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      return next({ name: 'Booking' })
    }
  },

  {
    path: '/booking',
    name: 'Booking',
    component: Booking
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: Tracking
  },
  {
    path: '/myaccount',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts
  },
  {
    path: '/contracts/:id',
    name: 'ContractDetail',
    component: ContractDetail
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/reservations',
    name: 'MyBookings',
    component: Reservations,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/reservations/:id',
    name: 'MyBookingsDetail',
    component: ReservationsDetail,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Login'
        })
      }
      next()
    }
  },
  {
    path: '/tracking-result',
    name: 'TrackingResult',
    component: TrackingResult
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const blocked_paths = [
    'Booking',
    'Reservations',
    'ReservationsDetail',
    'TrackingResult',
    'Tracking',
    'Booking.Result',
    'Login',
    'Contracts',
    'ContractDetail',
    'MyAccount',
    'MyProfile',
    'MyBookings',
    'MyBookingsDetail'
  ]
  if (!blocked_paths.some((e) => e === to.name)) {
    if (!store.state.booking.origin || !store.state.booking.destination) {
      return next({ name: 'Booking' })
    }
  }
  next()
})

export default router
