<template>
  <div>
    <ul>
      <li :key="contract.id" v-for="contract in contracts">
        <a :href="'/contracts/' + contract.id">{{ contract.name }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  mounted() {
    var lang = 'en'
    if (localStorage.getItem('lang')) {
      lang = JSON.parse(localStorage.getItem('lang'))
    }
    axios.get('api/contracts/active?lang=' + lang).then((response) => {
      this.contracts = response.data
    })
  },
  data() {
    return {
      contracts: null
    }
  }
}
</script>

<style scoped>
ul li {
  list-style: circle;
}
</style>
