import Vue from 'vue'
import Vuex from 'vuex'
import booking from './modules/booking'
import auth from './modules/auth'
import bookingStep from './modules/bookingStep'
import homeSettings from './modules/homeSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    booking,
    auth,
    bookingStep,
    homeSettings
  }
})
