<template>
  <div class="container">
    <TheModal
      v-bind:showModal="showModal"
      v-bind:title="''"
      v-bind:description="this.contracts"
      @close="logChange"
    />

    <div class="row">
      <div class="col-12">
        <div class="d-flex bread-crump-area">
          <a href="/"><i class="ri-home-7-fill"></i></a>
          <a href="/reservations">{{ $t('reservations') }}</a>
          <i class="ri-arrow-right-s-line"></i>
          <a>{{ $t('reservation_detail') }}</a>
          <i class="ri-arrow-right-s-line"></i>
          <a>{{ this.id }}</a>
        </div>
      </div>
      <div class="col-12 reservation-info-area">
        <div class="row">
          <div class="col">
            <div class="d-flex flex-column item">
              <b>{{ $t('Reservation_no') }}</b>
              <span v-if="this.data">{{ this.data.track_code }}</span>
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-column item">
              <b>{{ $t('date/time') }}</b>
              <span v-if="this.data"
                >{{ format_date(this.data.booking_date) }} /
                {{
                  format_time(
                    this.data.booking_date + ' ' + this.data.booking_time
                  )
                }}</span
              >
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-column item">
              <b>{{ $t('name_surname') }}</b>
              <span v-if="this.data"
                >{{ this.data.user.name }} {{ this.data.user.surname }}</span
              >
            </div>
          </div>
          <div class="col">
            <div class="d-flex flex-column item">
              <b>{{ $t('status') }}</b>
              <span v-if="this.data">{{ this.data.status_name }}</span>
            </div>
          </div>
          <!-- <div class="col">
            <TheButton size="btn-sm" :loading="loading">{{
              $t('Print Invoice')
            }}</TheButton>
          </div> -->
        </div>
      </div>
      <div class="col-12">
        <div class="intermediate line"></div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-lg-4 col-md-12" v-if="this.data.service">
            <div class="intermediate">
              <div class="reservation-item-">
                <div class="the-choose-your-vehicle-card">
                  <section v-if="!this.isPaid">
                    <ThePaymentModal
                      v-bind:showModal="showPaymentModal"
                      v-bind:paymentType="this.data.data.payment_type ?? 'Full'"
                      v-bind:bookingId="this.data.data.booking_id"
                      @close="closePaymentModal"
                    />

                    <TheButton
                      size="btn-sm"
                      :fullwidth="true"
                      @click="continuePaymentClick"
                      >{{ $t('continue_payment') }}
                    </TheButton>
                  </section>

                  <section v-if="this.data.cancelable && this.isPaid">
                    <TheButton
                      size="btn-sm"
                      color="red"
                      :fullwidth="true"
                      :loading="this.cancelButtonLoading"
                      @click="cancelReservationClick"
                      >{{ $t('cancel_reservation') }}
                    </TheButton>
                  </section>

                  <div class="the-choose-your-vehicle-img-box">
                    <img
                      v-if="this.data.service"
                      class="w-100"
                      :src="this.data.service.image_url"
                    />
                  </div>
                  <section
                    v-if="this.isHovering"
                    :class="{ hovering: isHovering }"
                    class="align-items-center d-flex justify-content-between price-area"
                  >
                    <strong v-if="this.data.service">{{
                      this.data.service.name
                    }}</strong>
                  </section>

                  <section class="d-flex flex-column align-items-start feature">
                    <abbr
                      ><i class="ri-user-add-line"></i>
                      <span v-if="this.data.service"
                        >{{ $t('max') }}.
                        {{ this.data.service.person_capacity }}
                        {{ $t('person') }}</span
                      ></abbr
                    >
                    <abbr
                      ><i class="ri-suitcase-3-line"></i>
                      <span v-if="this.data.service"
                        >{{ $t('max') }}.
                        {{ this.data.service.baggage_capacity }}
                        {{ $t('suitcase') }}</span
                      ></abbr
                    >
                    <abbr
                      v-if="
                        this.data.service &&
                        this.data.service.free_cancellation > 0
                      "
                      ><i class="ri-time-line"></i>
                      <span v-if="this.data"
                        >{{ $t('free_cancellation_up_to') }}
                        {{ this.data.service.free_cancellation }}
                        {{ $t('hours') }})</span
                      ></abbr
                    >
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            <div
              class="from-to-date-time d-flex justify-content-start flex-column align-items-start"
            >
              <div
                class="section-item d-flex justify-content-start flex-column align-items-start"
              >
                <b>{{ $t('from') }}</b>
                <span class="text-left">{{ this.data.from_name }}</span>
              </div>
              <i class="ri-arrow-down-line mt-3"></i>
              <div
                class="section-item d-flex justify-content-start flex-column align-items-start"
              >
                <b>{{ $t('to') }}</b>
                <span class="text-left">{{ this.data.to_name }}</span>
              </div>
              <i class="ri-arrow-down-line mt-3"></i>
              <div class="section-item d-flex justify-content-start w-100">
                <div class="d-flex flex-column align-items-start mr-5">
                  <b>{{ $t('date') }}</b>
                  <span class="text-left"
                    >{{ format_date(this.data.booking_date) }}
                  </span>
                </div>
                <div class="d-flex flex-column align-items-start">
                  <b class="text-left"
                    >{{ $t('time') }} <i class="ri-information-fill"></i
                  ></b>
                  <span class="text-left">
                    {{
                      format_time(
                        this.data.booking_date + ' ' + this.data.booking_time
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="intermediate line"></div>
                </div>
              </div>

              <div
                v-if="this.data.data.payment_type == 'Full'"
                class="payment-summary"
              >
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('payment_type') }}</b>
                  <b class="text-left">: {{ $t('full_payment') }} </b>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('payment_status') }}</b>
                  <b class="text-left">: {{ this.getPaymentStatus }} </b>
                </div>

                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('sub_total') }}</b>
                  <b class="text-left"
                    >: CHF {{ this.data.data.discount_price }}</b
                  >
                </div>

                <div
                  class="d-flex align-items-center mb-2"
                  v-if="this.data.data.full_discount > 0"
                >
                  <b class="title">{{ $t('discount') }}</b>
                  <b class="text-left"
                    >: CHF -{{
                      (
                        this.data.data.discount_price -
                        this.data.data.full_discount_price
                      ).toFixed(2)
                    }}</b
                  >
                  <span class="discount"
                    >% {{ this.data.data.full_discount }}
                    {{ $t('discount') }}</span
                  >
                </div>

                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('total_price') }}</b>
                  <b class="text-left"
                    >: CHF {{ this.data.data.full_discount_price }}</b
                  >
                </div>
              </div>

              <div
                v-if="this.data.data.payment_type == 'Pre'"
                class="payment-summary"
              >
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('payment_type') }}</b>
                  <b class="text-left">: {{ $t('pre_payment') }} </b>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('payment_status') }}</b>
                  <b class="text-left">: {{ this.getPaymentStatus }} </b>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('total_amount') }}</b>
                  <b class="text-left"
                    >: CHF {{ this.data.data.discount_price }}</b
                  >
                </div>
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('pre_payment') }}</b>
                  <b class="text-left"
                    >: CHF {{ this.data.data.system_payment }}</b
                  >
                </div>
                <div class="d-flex align-items-center mb-2">
                  <b class="title">{{ $t('paid_after_trip') }}</b>
                  <b class="text-left"
                    >: CHF {{ this.data.data.driver_payment }}</b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      v-for="item in contractsArr"
      :key="item"
      @click="privacyDetails(item.id)"
      class="privacy-detail"
      >{{ item.name }}</a
    >
  </div>
</template>

<script lang="js">
import moment from 'moment'
import axios from 'axios'
import Swal from 'sweetalert2'
import TheModal from '@/components/TheModal'
import TheButton from '@/components/TheButton'
import ThePaymentModal from '@/components/ThePaymentModal'

// import TheButton from '@/components/TheButton'
export default {
  name: 'ReservationsDetail',
  components: {
    // TheButton
    TheModal,
    TheButton,
    ThePaymentModal
  },
  mounted() {

    this.loading = true
    let $app = this
    axios
      .get(`api/getBookingsDetail/${this.$route.params.id}`)
      .then((response) => {
        this.data = response.data
        this.loading = false
        // console.log(response.data);
        //Sözleşme detayı
        this.contractsArr = response.data.contracts
      })
      .catch(function () {
        Swal.fire({
          title: this.$t('errors.booking_not_found'),
          text: this.$t('errors.booking_not_found_description'),
          icon: 'error',
          confirmButtonText: this.$t('close')
        }).then(() => {
          $app.$router.push({ name: 'MyBookings' })
        })
        this.loading = false
      })
  },
  computed: {

    isPaid: function() {
      return this.getPaymentStatus === "Paid"
    },
    getPaymentStatus: function() {
      return this.data.payment_status ?? "Not Paid"
    },

    final_price: function () {
      let final_price_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          final_price_total += parseFloat(item.final_price) || 0
        })
      }
      return parseFloat(final_price_total) || 0
    },
    discount: function () {
      let discount_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          discount_total += parseFloat(item.discount) || 0
        })
      }
      return parseFloat(discount_total) || 0
    },
    tax: function () {
      let tax_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          tax_total += parseFloat(item.tax) || 0
        })
      }
      return parseFloat(tax_total) || 0
    },
    sub_total: function () {
      let price_total = 0
      if (this.data.packets) {
        this.data.packets.forEach((item) => {
          price_total += parseFloat(item.price) || 0
        })
      }
      return parseFloat(price_total) || 0
    }
  },
  data() {
    return {
      data: null,
      isHovering: false,
      loading: false,
      cancelButtonLoading:false,
      contracts:null,
      contractsArr:null,
      showModal: false,
      fullPayment:null,
      showPaymentModal:false,
    }
  },
  methods: {
    logChange(event) {
      this.showModal = event
    },
    closePaymentModal(event) {
      this.showPaymentModal = event
    },

    async cancelReservationClick() {

      return Swal.fire({
          title: `${this.$t('cancel_reservation_question')}`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `${this.$t('yes')}`,
          denyButtonText: `${this.$t('no')}`
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.cancelButtonLoading=true;
            await axios.post('api/bookings/'+ this.data.data.booking_id +'/cancel');
            this.cancelButtonLoading=false;
            this.$router.go(this.$router.currentRoute)
          }
        })



    },
    async continuePaymentClick() {

      /*
     await axios.post('api/payment', {
      "bookingId":this.data.data.booking_id,
      "paymentType": this.data.data.payment_type ?? "Full"
      });
      */

      this.showPaymentModal=true;
    },
    privacyDetails(id){
      this.contracts = "Loading..."
      this.showModal = true
      axios
        .get(
          `api/userContractDetail/${id}`
        )
        .then((response) => {
          this.contracts = response.data
        // this.fullPayment = response.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    format_time(value) {
      if (value) {
        return moment(String(value)).format('HH:mm')
      }
    }
  },


}
</script>

<style lang="scss">
.privacy-detail {
  outline: #000;
  cursor: pointer;
  margin-top: 15px;
  display: flex;
}
.section-item {
  b {
    color: rgb(var(--text-color));
    text-align: left;
    font: normal normal 600 15px/23px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    position: relative;
    i {
      font-size: 20px !important;
      color: rgb(var(--secondary-color));
      position: absolute;
      top: 0;
      margin-top: -5px;
    }
  }
}
.payment-summary {
  width: 100%;
  .discount {
    margin-left: 20px;
    border-radius: 12px;
    opacity: 1;
    background-color: rgb(34, 167, 47);
    font: normal normal 300 9px/6px Poppins;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 7px 10px;
  }
  .title {
    min-width: 150px;
  }
  b {
    color: rgb(var(--text-color));
    text-align: left;
    font: normal normal 500 15px/23px Poppins;
    letter-spacing: 0px;
    opacity: 1;
  }
}
// .navigation {
//   // margin-bottom: 36px !important;
// }
.reservation-info-area {
  margin-top: 40px;
  width: 100%;
  button {
    padding: 10px 20px !important;
  }
}

.reservation-info-area {
  .item {
    b {
      color: rgb(var(--text-color));
      text-align: left;
      letter-spacing: 0px;
      opacity: 1;
      font-size: 14px;
      font-weight: 500;
    }
    span {
      color: rgb(var(--text-color));
      text-align: left;
      font: normal normal normal 13px/20px Poppins;
      letter-spacing: 0px;
      opacity: 1;
    }
  }
}
.bread-crump-area {
  height: 38px;
  background-color: #fff;
  background: rgb(var(--secondary-color)) 0% 0% no-repeat padding-box;
  border-radius: 32px;
  opacity: 1;
  align-items: center;
  padding: 12px 20px;
  a {
    text-align: left;
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1) !important;
  }
  i {
    color: rgba(255, 255, 255, 1);
    &:nth-child(1) {
      margin-right: 10px;
      font-size: 14px;
    }
  }
}

.intermediate line {
  border: 1px solid rgba(206, 212, 218, 1);
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.hovering {
  border-bottom: 3px solid rgb(var(--secondary-color)) !important;
}
.reservation-item- {
  justify-content: center;
  background-color: white;
  display: flex;
}
.the-choose-your-vehicle-card {
  width: 100%;
  padding: 20px;

  .the-choose-your-vehicle-img-box {
    min-height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .price-area {
    border-bottom: 3px solid rgb(var(--secondary-color));
    margin-bottom: 17px;
    padding-bottom: 7px;
    strong {
      font-size: 16px;
    }
    small {
      text-decoration: line-through;
      color: rgb(var(--tertiary-color));
      font-size: 12px;
    }

    span {
      font-size: 15px;
      color: rgb(var(--secondary-color));
      font-weight: 600;
    }
  }
  .button-container {
    width: 100%;
  }

  .feature {
    abbr {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 17px;
        color: rgb(var(--tertiary-color));
      }
      span {
        font: normal normal normal 13px/25px Poppins;
        color: rgb(var(--text-color));
        font-weight: 500;
      }
    }
  }
  .section-item {
    span {
      color: rgba(73, 80, 87, 1);
    }
  }
}
</style>
