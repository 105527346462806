<template>
  <div>
    <div class="index">
      <div class="origin">
        <TheLabel>{{ $t('tracking_code') }}</TheLabel>
        <TheTextbox
          v-model="code"
          :placeholder="$t('enter_your_tracking_code')"
        />
      </div>
      <div class="destination">
        <TheButton @click="submit">{{ $t('search') }}</TheButton>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import TheButton from '@/components/TheButton'
import TheLabel from '@/components/TheLabel'
import TheTextbox from '@/components/TheTextbox'

export default {
  name: 'Tracking',
  components: {
    TheButton,
    TheLabel,
    TheTextbox
  },
  data() {
    return {
      code: null
    }
  },
  methods: {
    submit() {
      this.$router.push({ name: 'TrackingResult', query: { code: this.code } })
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }

  label {
    margin-bottom: 10px;
    display: block;
  }
  .pac-target-input {
    border: 1px solid rgb(var(--border-color));
    color: rgb(var(--text-color));
    border-radius: 4px;
    font-size: 1rem;
    padding: 20px 10px;
  }
  @media (--md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }

  .origin,
  .destination {
    margin-bottom: 30px;
  }

  .destination {
    align-items: flex-start;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  }
}
</style>
